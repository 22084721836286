import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Form,
  FormControl,
  FormLabel,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import {
  faTimes,
  faSave,
  faRedo,
  faLocation,
  faInfoCircle,
  faBackwardStep,
  faCheck,
  faCancel,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toggleAddAgent, toggleNominationReq } from '../slices/dashboardSlice';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ChangeEvent, ChangeEventHandler, useState } from 'react';
import { AppDispatch, RootState } from '../../../store';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { updateSelectedTab } from '../../nominationReq/slices/nominationReq';
import { toggleDocumentHistoryColumnShort } from '../../blDetails/slices/documentHistorySlice';
import { enableNominateAgent } from '../../standingInstruction/slices/standingInstructionTable';
import FileUpload from '../../blPayment/FileUpload';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAsyncDebounce } from 'react-table';
import { fetchAddressSuggestions2 } from '../actions/instruction';
import { getCountryList } from '../../registration/actions/Registration';
import { updateSelectedAddressResult } from '../../blRequestInstruction/components/instructionSlice/CarrierHaulageSlice';
import { InstructionList } from '../types';
import React, { useEffect } from 'react';
import {
  addAgentNnominate,
  getAgentNnominate,
  getCodeList,
  addBlanketDeligation,
  getBlanketDeligation,
} from '../../blRequestInstruction/components/actions/Instruction';
import { getInstructionList } from '../actions/instruction';
import { resolve } from 'path';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCompanyDetailsForAgentDelegation } from '../actions/instruction';
import { Root } from 'react-dom/client';
import {
  clearFields,
  toggleDisableFields,
  toggleGrantFrtInvAccess,
  toggleBlAccess,
  toggleTimePendingInvoiceAccess,
  updateAgentAccessList,
  resetBlanket,
} from '../../blRequestInstruction/components/instructionSlice/AgentNominationSlice';
import { AESDecrypt } from '../../../../encrypt-util';
import SoftBadge from '../../../../components/common/SoftBadge';
import {
  faArrowAltCircleLeft,
  faEdit,
} from '@fortawesome/free-regular-svg-icons';
import InputMask from 'react-input-mask';
import AgentDelegationTNC from './agentDelegationTnc';
import { commonApproveReject } from '../actions/instruction';
import { IDataTableColumn } from '../../../common/types/dataTable';
import DataTable from '../../../common/components/DataTable';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { MultiSelect } from 'react-multi-select-component';
import { FALSE, Value } from 'sass';
import { json } from 'stream/consumers';
import { getDateFormat } from '../../../../helpers/dateFormat';

const BlanketDelegation = () => {
  const data = useSelector(
    (state: RootState) => state.agentDelegation.blanketData
  );
  //const blList = useSelector((state: RootState) => state.dashboard.data);

  const invTypeList = useSelector(
    (state: RootState) => state.agentDelegation.invTypeList
  );

  const agentAccessList = useSelector(
    (state: RootState) => state.agentDelegation.agentAccessList
  );

  let selectedTab = useSelector(
    (state: RootState) => state.nominationReq.selectedTab
  );

  const countryOptions = useSelector(
    (state: RootState) => state.registration.countryOptions
  );

  const [clearable, setClearable] = useState(true);

  // const dataCountry = useSelector(
  //   (state: RootState) => state.userProfile.linerDataCountry
  // );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
    ? linerDataCountry
    : profileDetails.partnerCountry || null;

  const selectedRouter = useSelector(
    (state: RootState) => state.documentHistory.selectedRouter
  );
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();
  // const docId = location.pathname.split('/')[4];

  const navigate = useNavigate();
  const { blNum, encrypt, encryptdoc, blId } = useParams();
  const instructionIdurl = AESDecrypt(encryptdoc);
  const decrptdoc = AESDecrypt(encryptdoc);
  const decrptins = AESDecrypt(encrypt);
  const docId = AESDecrypt(encryptdoc);

  const [taxId, setTaxId] = useState('');
  const [address, setAddress] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [email, setEmail] = useState('');
  const [loiUpload, setLoiUpload] = useState('');
  const [byteArr, setByteArr] = useState('');
  const [fileName, setFileName] = useState('');
  const [agentPartnerId, setAgentPartnerId] = useState('');
  const [allowFreightInvoice, setAllowFreightInvoice] = useState('');
  const [allowBlAccess, setAllowBlAccess] = useState('');
  const [allowTimePendingInvoice, setAllowTimePendingInvoice] = useState('');
  const [agentNominationId, setAgentNominationId] = useState(0);
  const [status, setStatus] = useState('');
  const [taxIdFormat, setTaxIdFormat] = useState('');

  //all instruction List
  const instructionList = useSelector(
    (state: RootState) => state.documentHistory.data
  );

  const [state, setState] = useState<any>({
    taxId: '',
    companyName: '',
    emailId: '',
    fileName: '',
    address: '',
    agentPartnerId: '',
    allowFreightInvoice: '',
    agentNominationId: '',
    status: '',
    allowTimePendingInvoice: '',
    allowBlAccess: '',
    customerRemarks: '',
    rejectRemark: '',
    polCountry: '',
    podCountry: '',
    startCountry: '',
    validTill: '',
    validForm: '',
    //cargoType: [],
  });
  const matchPth = [
    { path: '/instruction/:blNum/:encrypt/:encryptdoc' },
    { path: '/bl/:blNum/:encrypt/:encryptdoc' },
    { path: 'instruction/bl/:blNum/:encrypt/:encryptdoc' },
    { path: '/standing/:bl_id/:encrypt/:standing_instruction_id' },
  ].some(({ path, condition }: { path: string; condition?: boolean }) => {
    return (
      (condition ?? true) &&
      matchPath({ path, caseSensitive: true, end: true }, location.pathname)
    );
  });

  const selectedLinerId = useSelector(
    (state: RootState) => state.navbarTop.linerId
  );
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);

  const currentDate = new Date();

  useEffect(() => {
    dispatch(getCountryList(1))
      .unwrap()
      .then((response: any) => {
        response.map((option: any) => {
          if (option.countryShortCode === profileDetails.partnerCountry) {
            setTaxIdFormat(option.taxIdFormat);
          }
        });
      });
  }, []);

  useEffect(() => {
    if (instructionIdurl && selectedLinerId) {
      dispatch(
        getBlanketDeligation({
          docId: instructionIdurl,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails.partnerId
              : selectedLinerId,
        })
      );
    } else {
      dispatch(resetBlanket({}));
    }
  }, [instructionIdurl, selectedLinerId]);

  const isGrantFrtInvAccess = useSelector(
    (state: RootState) => state.agentDelegation.isGrantFrtInvAccess
  );

  const isBlAccess = useSelector(
    (state: RootState) => state.agentDelegation.isBlAccess
  );

  const isTimePendingInvoiceAccess = useSelector(
    (state: RootState) => state.agentDelegation.isTimePendingInvoiceAccess
  );
  // useEffect(() => {
  //   isGrantFrtInvAccess? setAllowFreightInvoice('Y') : setAllowFreightInvoice('N');
  // })

  const options = [
    { label: 'General', value: 52, disable: true },
    { label: 'Hazardous', value: 51 },
    { label: 'Reefer', value: 35 },
  ];

  const [termsAccepted, setTermsAccepted] = useState(dataCountry == 'US');

  const onToggleGrantFrtInvAccess = () => {
    dispatch(toggleGrantFrtInvAccess(!isGrantFrtInvAccess));
  };
  const onToggleBlAccess = () => {
    dispatch(toggleBlAccess(!isBlAccess));
  };
  const onToggleTimePendingInvoiceAccess = () => {
    dispatch(toggleTimePendingInvoiceAccess(!isTimePendingInvoiceAccess));
  };

  const selectedAddressResult = useSelector(
    (state: RootState) => state.carrierHaulage.selectedAddressResult
  );

  const isAddressSuggesstionLoading = useSelector(
    (state: RootState) => state.carrierHaulage.isAddressSuggesstionsLoading
  );

  const registrationAddressSuggesstion = useSelector(
    (state: RootState) => state.carrierHaulage.registrationAddressSuggesstion
  );

  const addr = useSelector((state: RootState) => state.agentDelegation.address);
  const compName = useSelector(
    (state: RootState) => state.agentDelegation.companyName
  );
  const emailId = useSelector(
    (state: RootState) => state.agentDelegation.emailId
  );
  const partnerId = useSelector(
    (state: RootState) => state.agentDelegation.agentPartnerId
  );
  const selectedDataNominatedAgent = data;

  const setInitialValue = () => {
    dispatch(clearFields({}));
    resetForm();
    formReset();
    dispatch(toggleAddAgent(false));
    dispatch(toggleNominationReq(false));
    dispatch(updateSelectedTab(''));
    dispatch(toggleGrantFrtInvAccess(false));
    setTermsAccepted(dataCountry === 'US');
    if (matchPth) {
      dispatch(toggleDisableFields(true));
      //  const selectedDataNominatedAgent = data?.list as any;

      if (
        !matchPathInstruction() &&
        selectedDataNominatedAgent?.agentNominationDetails?.agentTaxId
      ) {
        setTaxId(
          selectedDataNominatedAgent?.agentNominationDetails?.agentTaxId
        );
      }
      if (
        selectedDataNominatedAgent?.agentNominationDetails?.agentTaxId ||
        taxId
      ) {
        setValue(
          'taxId',
          selectedDataNominatedAgent?.agentNominationDetails?.agentTaxId ||
            taxId
        );
      }
      // setTaxId(
      //   !matchPathInstruction()
      //     ? selectedDataNominatedAgent?.agentNominationDetails.agentTaxId || ''
      //     : ''
      // );
      setValue('startCountry', selectedDataNominatedAgent?.startPol ?? null);
      setValue('polCountry', selectedDataNominatedAgent?.pol ?? null);
      setValue('podCountry', selectedDataNominatedAgent?.pod ?? null);
      setValue('endCountry', selectedDataNominatedAgent?.finalPod ?? null);

      /*  if (!matchPathInstruction()) {
        if (
          selectedDataNominatedAgent?.cargoType &&
          selectedDataNominatedAgent?.cargoType !== null
        ) {
          const cargoTypeList =
            selectedDataNominatedAgent?.cargoType?.split(',');
          if (cargoTypeList.length > 0) {
            const cargoTypeName = cargoTypeList?.map((v: any) => {
              return {
                value: v,
                label: options?.find((u: any) => v == u?.value)?.label,
                
              };
            });
            if (cargoTypeName) setCargoType(cargoTypeName);
          }
        }
      }
      {
        setValue(
          'cargoType',
          selectedDataNominatedAgent?.cargoTypeName || cargoType
        );
      } */
      if (selectedDataNominatedAgent?.validFrom) {
        setValue(
          'validForm',
          new Date(selectedDataNominatedAgent?.validFrom) ??
            (() => {
              const date = new Date();
              date.setHours(0, 0, 0, 0);
              return date;
            })() ??
            null
        );
      }

      if (selectedDataNominatedAgent?.validTo) {
        setValue(
          'validTill',
          new Date(selectedDataNominatedAgent?.validTo) ||
            (() => {
              const date = new Date();
              date.setHours(0, 0, 0, 0);
              return date;
            })()
        );
      }

      /*    if (!matchPathInstruction() && selectedDataNominatedAgent?.validTo) {
        setValidTill(selectedDataNominatedAgent?.validTo);
      } */

      /* if (!matchPathInstruction() && selectedDataNominatedAgent?.cargoType) {
        setCargoType(selectedDataNominatedAgent?.cargoType);
      } */

      /*   if (
        !matchPathInstruction() &&
        selectedDataNominatedAgent
          ?.moment(validTill)
          .format('YYYY-MM-DDTHH:mm:ss')
      ) {
        setValidTill(
          selectedDataNominatedAgent
            ?.moment(validTill)
            .format('YYYY-MM-DDTHH:mm:ss')
        );
      } */

      if (
        !matchPathInstruction() &&
        selectedDataNominatedAgent?.agentNominationDetails?.agentPartnerName
      ) {
        setCompanyName(
          selectedDataNominatedAgent?.agentNominationDetails?.agentPartnerName
        );
      }
      if (
        selectedDataNominatedAgent?.agentNominationDetails?.agentPartnerName ||
        companyName
      ) {
        setValue(
          'companyName',
          selectedDataNominatedAgent?.agentNominationDetails
            ?.agentPartnerName || companyName
        );
      }
      // setCompanyName(
      //   !matchPathInstruction()
      //     ? selectedDataNominatedAgent?.agentNominationDetails.agentPartnerName || ''
      //     : ''
      // );
      if (
        !matchPathInstruction() &&
        selectedDataNominatedAgent?.agentNominationDetails?.agentEmailId
      ) {
        setEmail(
          selectedDataNominatedAgent?.agentNominationDetails?.agentEmailId
        );
      }
      if (
        selectedDataNominatedAgent?.agentNominationDetails?.agentEmailId ||
        email
      ) {
        setValue(
          'email',
          selectedDataNominatedAgent?.agentNominationDetails?.agentEmailId ||
            email
        );
      }
      // setEmail(
      //   !matchPathInstruction()
      //     ? selectedDataNominatedAgent?.agentNominationDetails.agentEmailId || ''
      //     : ''
      // );
      if (
        !matchPathInstruction() &&
        selectedDataNominatedAgent?.agentNominationDetails?.fileName
      ) {
        setFileName(
          selectedDataNominatedAgent?.agentNominationDetails?.fileName
        );
      }
      // setFileName(
      //   !matchPathInstruction()
      //     ? selectedDataNominatedAgent?.agentNominationDetails.fileName || ''
      //     : ''
      // );
      if (
        !matchPathInstruction() &&
        selectedDataNominatedAgent?.agentNominationDetails?.address
      ) {
        setAddress(selectedDataNominatedAgent?.agentNominationDetails?.address);
      }
      if (
        selectedDataNominatedAgent?.agentNominationDetails?.address ||
        address
      ) {
        setValue(
          'address',
          selectedDataNominatedAgent?.agentNominationDetails?.address || address
        );
      }
      // setAddress(
      //   !matchPathInstruction()
      //     ? selectedDataNominatedAgent?.agentNominationDetails.address || ''
      //     : ''
      // );
      if (
        !matchPathInstruction() &&
        selectedDataNominatedAgent?.agentNominationDetails?.allowFreightInvoice
      ) {
        setAllowFreightInvoice(
          selectedDataNominatedAgent?.agentNominationDetails
            ?.allowFreightInvoice
        );
      }
      if (
        selectedDataNominatedAgent?.agentNominationDetails
          ?.allowFreightInvoice ||
        allowFreightInvoice
      ) {
        setValue(
          'allowFreightInvoice',
          selectedDataNominatedAgent?.agentNominationDetails
            .allowFreightInvoice || allowFreightInvoice
        );
      }
      if (
        !matchPathInstruction() &&
        selectedDataNominatedAgent?.agentNominationDetails?.timePendingInvoice
      ) {
        setAllowTimePendingInvoice(
          selectedDataNominatedAgent?.agentNominationDetails?.timePendingInvoice
        );
      }
      if (
        selectedDataNominatedAgent?.agentNominationDetails
          ?.timePendingInvoice ||
        allowTimePendingInvoice
      ) {
        setValue(
          'allowTimePendingInvoice',
          selectedDataNominatedAgent?.agentNominationDetails
            ?.timePendingInvoice || allowTimePendingInvoice
        );
      }

      if (
        !matchPathInstruction() &&
        selectedDataNominatedAgent?.agentNominationDetails?.blAccess
      ) {
        setAllowBlAccess(
          selectedDataNominatedAgent?.agentNominationDetails?.blAccess
        );
      }
      if (
        selectedDataNominatedAgent?.agentNominationDetails?.blAccess ||
        allowBlAccess
      ) {
        setValue(
          'allowBlAccess',
          selectedDataNominatedAgent?.agentNominationDetails?.blAccess ||
            allowBlAccess
        );
      }
      // setAllowFreightInvoice(
      //   !matchPathInstruction()
      //     ? selectedDataNominatedAgent?.agentNominationDetails.allowFreightInvoice || ''
      //     : ''
      // );
      if (
        !matchPathInstruction() &&
        selectedDataNominatedAgent?.agentNominationDetails?.agentNominationId
      ) {
        setAgentNominationId(
          selectedDataNominatedAgent?.agentNominationDetails?.agentNominationId
        );
      }
      if (
        selectedDataNominatedAgent?.agentNominationDetails?.agentNominationId ||
        agentNominationId
      ) {
        setValue(
          'agentNominationId',
          selectedDataNominatedAgent?.agentNominationDetails
            ?.agentNominationId || agentNominationId
        );
      }
      // setAgentNominationId(
      //   !matchPathInstruction()
      //     ? selectedDataNominatedAgent?.agentNominationDetails.agentNominationId || ''
      //     : ''
      // );
      if (
        !matchPathInstruction() &&
        selectedDataNominatedAgent?.agentNominationDetails?.agentPartnerId
      ) {
        setAgentPartnerId(
          partnerId
            ? partnerId
            : selectedDataNominatedAgent?.agentNominationDetails?.agentPartnerId
        );
      }
      if (
        selectedDataNominatedAgent?.agentNominationDetails?.agentPartnerId ||
        agentPartnerId ||
        partnerId
      ) {
        setValue(
          'agentPartnerId',
          partnerId
            ? partnerId
            : selectedDataNominatedAgent?.agentNominationDetails
                ?.agentPartnerId || agentPartnerId
        );
      }
      // setAgentPartnerId(
      //   !matchPathInstruction()
      //     ? selectedDataNominatedAgent?.agentNominationDetails.agentPartnerId || ''
      //     : ''
      // );

      setState({
        ...state,
        customerRemarks:
          selectedDataNominatedAgent?.agentNominationDetails?.customerRemarks,
      });

      if (!matchPathInstruction() && selectedDataNominatedAgent?.status) {
        setStatus(selectedDataNominatedAgent?.status);
        setValue('status', selectedDataNominatedAgent?.status);
      }

      if (
        !matchPathInstruction() &&
        selectedDataNominatedAgent?.agentNominationDetails?.agentAccessList
      ) {
        dispatch(
          updateAgentAccessList(
            selectedDataNominatedAgent?.agentNominationDetails?.agentAccessList?.map(
              (v: any) => {
                return {
                  agentAccessId: v?.agentAccessId || null,
                  agentNominationId: v?.agentNominationId || null,
                  type: v?.type,
                  allowAccess: v?.allowAccess,
                };
              }
            )
          )
        );
      }
    }
  };

  useEffect(() => {
    setInitialValue();
    clearErrors()
  }, [location.pathname, data]);
  useEffect(() => {
    dispatch(
      toggleGrantFrtInvAccess(allowFreightInvoice == 'Y' ? true : false)
    );
  }, [allowFreightInvoice]);
  useEffect(() => {
    dispatch(toggleBlAccess(allowBlAccess == 'Y' ? true : false));
  }, [allowBlAccess]);
  useEffect(() => {
    dispatch(
      toggleTimePendingInvoiceAccess(
        allowTimePendingInvoice == 'Y' ? true : false
      )
    );
  }, [allowTimePendingInvoice]);
  const resetForm = () => {
    setTaxId('');
    setAddress('');
    setCompanyName('');
    setEmail('');
    setLoiUpload('');
    setValue('startCountry', '');
    setValue('polCountry', '');
    setValue('podCountry', '');
    setValue('endCountry', '');
    // setCargoType([]);
  };
  const formReset = () => {
    setState({
      //  blId: AESDecrypt(blNum),

      taxId: '',
      companyName: '',
      emailId: '',
      fileName: '',
      address: '',
      agentPartnerId: '',
      allowFreightInvoice: '',
      agentNominationId: '',
      status: '',
      allowTimePendingInvoice: '',
      allowBlAccess: '',
      customerRemarks: '',
      rejectRemark: '',
      endCountry: '',
    });
  };
  useEffect(() => {
    const selectedDataNominatedAgent = data;
    const selectedDataNominatedAgent2 = data;
    setState({
      blId: AESDecrypt(blNum),

      taxId: selectedDataNominatedAgent?.agentNominationDetails?.taxId,
      companyName:
        selectedDataNominatedAgent?.agentNominationDetails?.companyName,
      emailId: selectedDataNominatedAgent?.agentNominationDetails?.emailId,
      fileName: selectedDataNominatedAgent?.agentNominationDetails?.fileName,
      address: selectedDataNominatedAgent?.agentNominationDetails?.address,
      agentPartnerId:
        selectedDataNominatedAgent?.agentNominationDetails?.agentPartnerId,
      allowFreightInvoice:
        selectedDataNominatedAgent?.agentNominationDetails?.allowFreightInvoice,
      allowBlAccess:
        selectedDataNominatedAgent?.agentNominationDetails?.blAccess,
      allowTimePendingInvoice:
        selectedDataNominatedAgent?.agentNominationDetails?.timePendingInvoice,
      agentNominationId:
        selectedDataNominatedAgent?.agentNominationDetails?.agentNominationId,
      status: selectedDataNominatedAgent?.agentNominationDetails?.status,
      customerRemarks:
        selectedDataNominatedAgent2?.agentNominationDetails?.customerRemarks,
      rejectRemark:
        selectedDataNominatedAgent2?.agentNominationDetails?.rejectRemark,
    });
  }, [location.pathname, data, blNum]);

  useEffect(() => {
    dispatch(
      getCodeList({
        codeType: 'INV_TYPE',
        linerId: linerId,
        partnerCountry:
          profileDetails.partnerType === 'liner'
            ? dataCountry || 'US'
            : profileDetails?.partnerCountry || 'US',
      })
    );
  }, [location]);

  const [selectedAccess, setSelectedAccess] = useState<any>({});

  useEffect(() => {
    //for new instruction set type

    if (invTypeList.length > 0) {
      if (
        location.pathname.split('/')[1] === 'instruction' ||
        matchPath('/standing/:encrypt', location.pathname)
      ) {
        dispatch(
          updateAgentAccessList(
            invTypeList.map((v: any) => {
              return {
                agentAccessId: null,
                agentNominationId: null,
                type: v?.value,
                allowAccess: 'Y',
              };
            })
          )
        );
      } else {
        dispatch(
          updateAgentAccessList(
            selectedDataNominatedAgent?.agentNominationDetails?.agentAccessList?.map(
              (v: any) => {
                return {
                  agentAccessId: v?.agentAccessId || null,
                  agentNominationId: v?.agentNominationId || null,
                  type: v?.type,
                  allowAccess: v?.allowAccess,
                };
              }
            )
          )
        );
      }
    }
  }, [invTypeList]);

  const matchPathInstruction = () =>
    matchPath(
      {
        path: '/bl/:blNum/instruction/:encrypt',
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: 'instruction/bl/:blNum/instruction/:encrypt',
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: '/standing/:encrypt',
        caseSensitive: true,
        end: true,
      },
      location.pathname
    );
  const disableFields = useSelector(
    (state: RootState) => state.agentDelegation.disableFields
  );
  useEffect(() => {
    dispatch(
      toggleDisableFields(
        profileDetails?.partnerType === 'liner' ||
          (!!matchPth && profileDetails?.partnerType !== 'liner')
      )
    );

    // setDisableFields(
    //   profileDetails?.partnerType === 'liner' ||
    //   (!!matchPth && profileDetails?.partnerType !== 'liner'));
  }, []);
  const _onCloseForm = () => {
    if (location.pathname.split('/')[1] === 'instruction') {
      navigate('/instruction');
    } else if (location.pathname.split('/')[1] === 'standing') {
      navigate('/standing');
    } else if (matchPath('/instruction/:blnum/:docid/:id', location.pathname)) {
      navigate(`/instruction`);
    } else if (selectedRouter === 'instruction') {
      navigate(`/instruction/${encodeURIComponent(blNum || '')}`);
    } else if (selectedRouter === 'payment') {
      navigate(`/payment/${encodeURIComponent(blNum || '')}`);
    } else if (decrptins == 810) {
      navigate(`/bl/${encodeURIComponent(blNum || '')}`);
    } else {
      dispatch(enableNominateAgent(false));
    }

    dispatch(toggleDocumentHistoryColumnShort(false));
  };

  type FormValues = {
    taxId: string;
    address: string;
    companyName: string;
    startCountry: string;
    // startPort: string;
    endCountry: string;
    //endPort: string;
    cargoType: any;
    polCountry: string;
    //polPort: string;
    podCountry: string;
    //podPort: string;
    validTill: any;
    validForm: any;
    email: string;
    // contactNo: string;
    //loiUpload: any;
    //byteArr: string;
    agentPartnerId: any;
    agentNominationId: any;
    allowFreightInvoice: any;
    status: string;
    allowTimePendingInvoice: string;
    allowBlAccess: string;
  };

  const validationSchema = Yup.object().shape({
    taxId: Yup.string()
      .required(t('resource_3:isRequired', { field: t('resource_2:taxId') }))
      .when('country', {
        is: 'USA',
        then: Yup.string().matches(
          /^\d\d-\d{7}$/,
          'Invalid input format. It should match the pattern "DD-#######"'
        ),
      }),
    /*  polCountry: Yup.string().required(
      t('resource_3:isRequired', { field: t('resource_1:pol') })
    ), */
    /*
    podCountry: Yup.string().required(
      t('resource_3:isRequired', { field: t('resource_1:pod') })
    ), */
    /*  cargoType: Yup.array()
      .nullable()
      .required(
        t('resource_3:isRequired', { field: t('resource_2:cargoType') })
      ), */
    validTill: Yup.date()
      .nullable()
      .required(
        t('resource_3:isRequired', { field: t('resource_2:validTill') })
      ),
    validForm: Yup.date()
      .nullable()
      .required(
        t('resource_3:isRequired', { field: t('resource_2:validForm') })
      ),
    address: Yup.string().required(
      t('resource_3:isRequired', { field: t('resource_1:address') })
    ),
    companyName: Yup.string().required(
      t('resource_3:isRequired', { field: t('resource_2:companyName') })
    ),
    email: Yup.string()
      .email(t('resource_3:isInvalid', { field: t('resource_1:email') }))
      .required(t('resource_3:isRequired', { field: t('resource_1:email') })),

    //contactNo: Yup.string().required(' Contact No is required'),
    //loiUpload: Yup.string().required('Upload LOI '),
    //byteArr: Yup.string().required('Upload LOI'),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors, touchedFields },
    watch,
    clearErrors,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      validForm: (() => {
        const date = new Date();
        date.setDate(new Date().getDate() + 1);
        date.setHours(0, 0, 0, 0);
        return date;
      })(),
    },
    mode: 'onChange',
  });
  const onSubmit = handleSubmit(
    async (payload: any) => {
      try {
        if (payload.startCountry === null) delete payload.startCountry;
        if (payload.polCountry === null) delete payload.polCountry;
        if (payload.podCountry === null) delete payload.podCountry;
        if (payload.endCountry === null) delete payload.endCountry;
        dispatch(
          addBlanketDeligation({
            ...payload,
            blId: AESDecrypt(blNum).toString(),
            //  byteArr: byteArr,
            // fileName: fileName,
            timePendingInvoice: isTimePendingInvoiceAccess ? 'Y' : 'N',
            linerId: linerId,
            country: dataCountry,
            blAccess: isBlAccess ? 'Y' : 'N',
            allowFreightInvoice: allowFreightInvoice ? 'Y' : 'N',
            validForm:
              moment(watch('validForm')).format('YYYY-MM-DDTHH:mm:ss') ||
              'null',
            validTill: moment(watch('validTill')).format('YYYY-MM-DDTHH:mm:ss'),
            instructionName: t('resource_2:blanketDelegation'),
            standingInstructionId: location.pathname.includes(
              '/standing/:bl_id'
            )
              ? parseInt(docId)
              : null,
            /*  cargoType:
              cargoType.length > 0
                ? cargoType.map((v: any) => v.value.toString()).join(',')
                : null, */
            // agentType: 'consumer',
            // customerRemarks: state?.customerRemarks || null,
            cargoType: options?.map((v: any) => v.value.toString()).join(','),
            accessList: agentAccessList.map((v: any) => {
              return {
                ...v,
                requestAccess: v?.allowAccess || null,
              };
            }),
            isMultiReq: true,
            requestType: '805',
            partyName: profileDetails?.partnerName || '',
            partyEmail: profileDetails?.email || ''
            // status: 'Completed',
            //  requestType: 'agent',
          })
        )
          .unwrap()
          .then((v: any) => {
            if (v?.data?.responseMsg && v?.data?.responseCode === 'SUCCESS') {
              toast.success(v?.data?.responseMsg);

              reset();

              if (location.pathname.split('/')[1] === 'multiBl') {
                navigate('/home');
              } else if (location.pathname.split('/')[1] === 'standing') {
                navigate('/standing');
              } else if (location.pathname.split('/')[1] === 'instruction') {
                setTimeout(() => {
                  dispatch(
                    getInstructionList({
                      blNum: AESDecrypt(blNum),
                      country:
                        dataCountry ||
                        /*  profileDetails.partnerCountry || */ '',
                    })
                  );
                }, 2000);
                navigate('/instruction');
              } else {
                setTimeout(() => {
                  dispatch(
                    getInstructionList({
                      blNum: AESDecrypt(blNum),
                      country:
                        dataCountry ||
                        /*  profileDetails.partnerCountry || */ '',
                    })
                  );
                }, 2000);
                if (location.pathname.split('/')[1] === 'standing')
                  navigate('/standing');
                else navigate(`/bl/${encodeURIComponent(blNum || '')}`);
              }
            } else {
              toast.error(v?.data?.responseMsg);
            }
          });
        // toast.dark(t('resource_2:successfullyCreated'));
      } catch (err: any) {
        console.error(err.message);
      }
    }

  );

  const onApproveReject = (status: string, type: string) => {
    if (status && type) {
      dispatch(
        commonApproveReject({
          instructionId: docId ? parseInt(docId) || null : null,
          instructionType: type || null,
          country:dataCountry,
          linerRemarks: state?.rejectRemark || null,
          customerRemarks: state?.customerRemarks || null,
          status: status || null,
          emailTo:
            selectedDataNominatedAgent?.agentNominationDetails.agentEmailId ||
            null,
          accessList: agentAccessList.map((v: any) => {
            return {
              ...v,
              requestAccess: v?.allowAccess || null,
            };
          }),
        })
      )
        .unwrap()
        .then((response: any) => {
          toast.success(response?.responseMsg);
        })
        .catch((error: any) => {
          toast.error(error);
        });

      //update instruction list and redirect to previous page

      if (location.pathname.split('/')[1] === 'standing') {
        navigate('/standing');
      } else {
        navigate(`/bl/${encodeURIComponent(blNum || '')}`);
      }
    }
  };

  const _onAddressChange = useAsyncDebounce((value: string) => {
    var countryCode = 'USA';
    if (profileDetails?.partnerCountry === 'US') {
      countryCode = 'USA';
    } else if (profileDetails?.partnerCountry === 'SG') {
      countryCode = 'SGP';
    } else if (
      profileDetails?.partnerCountry === 'ZA' ||
      profileDetails?.partnerCountry === 'ZAF'
    ) {
      countryCode = 'ZAF';
    }else if (profileDetails?.partnerCountry === 'KE') {
      countryCode = 'KEN';
    }else if(profileDetails.partnerCountry == 'JO'){
      countryCode = 'JOR'
    }
    dispatch(
      fetchAddressSuggestions2({
        text: value,
        country: countryCode,
      })
    );
  }, 200);

  useEffect(() => {
    if (taxId !== '' /*  && partnerId */ && !disableFields && !(
      location.pathname.includes(':bl_id'))) {
      addr !== '' ? setAddress(addr) : setAddress('');
      compName !== '' ? setCompanyName(compName) : setCompanyName('');
      emailId !== '' ? setEmail(emailId) : setEmail('');
      partnerId !== '' ? setAgentPartnerId(partnerId) : setAgentPartnerId('');
      setValue('companyName', compName ? compName : '');
      setValue('email', emailId ? emailId : '');
      setValue('address', addr ? addr : '');
      setValue('agentPartnerId', partnerId ? partnerId : '');
      // setValue('cargoTYpe';'')
    } else if (taxId && !partnerId && !agentPartnerId) {
      setAddress('');
      setCompanyName('');
      setEmail('');
      setAgentPartnerId('');
      setValue('companyName', '');
      setValue('email', '');
      setValue('address', '');
      setValue('agentPartnerId', '');
    }
    // } else if(!agentPartnerId && taxId !== '') {
    //     toast.error('Entered partner is not available on the ODeX, Kindly request Partner to get registered on the ODeX');
    // }
  }, [taxId, addr, compName, emailId, partnerId, disableFields]);

  const rbac = useSelector((state: RootState) => state.userProfile);

  const [tncDialog, setShowTncDialog] = useState(false);

  const closeTncDialog = () => setShowTncDialog(false);
  const showTncDialog = () => setShowTncDialog(true);
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      new Promise(() => {
        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          setByteArr(reader.result ? reader.result.toString() : '');
        };
      });
      setFileName(file.name);
    }
  };

  const isAccess = (rowData: any) => {
    const { type, allowAccess } = rowData.row.original;
    const name = 'isAccess' + type.toString();
    return (
      <div className='row'>
        <div className='col-lg-4'>
          <input
            type='checkbox'
            id={name}
            name={name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              dispatch(
                updateAgentAccessList(
                  agentAccessList.map((x: any) => {
                    if (x.type === type) {
                      return {
                        ...x,
                        allowAccess: e?.target?.checked ? 'Y' : 'N',
                      };
                    } else {
                      return x;
                    }
                  })
                )
              );
            }}
            checked={allowAccess && allowAccess === 'Y' ? true : false}
            disabled={disableFields}
          />
        </div>
      </div>
    );
  };

  const blAccessColumns: IDataTableColumn[] = [
    {
      accessor: 'agentAccessId',
      Header: 'agentAccessId',
    },
    {
      accessor: 'agentNominationId',
      Header: 'agentNominationId',
    },
    {
      accessor: 'type',
      Header: 'Grant Invoice Access',
      Cell: (rowData: any) => {
        const { type } = rowData.row.original;
        return invTypeList?.find((v: any) => v.value === type)?.text;
      },
      width: 300,
      maxWidth: 300,
    },
    {
      accessor: 'allowAccess',
      Header: '',
      width: 200,
      Cell: isAccess,
      maxWidth: 150,
    },
  ];

  return (
    <Card style={{ height: '100%', overflow:'auto' }}>
      <Card.Header className='border-bottom d-flex justify-content-between align-items-center'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1'
          onClick={_onCloseForm}
        />
        {selectedTab === '' ? (
          <div className='row col-12'>
            <div className='col-lg-4'>
              <h2 className='fs-0 text-primary'>
                {' '}
                {t('resource_2:blanketDelegation')}
              </h2>
            </div>
          </div>
        ) : (
          ''
        )}
        {!matchPth && selectedTab != '' ? (
          <>
            {t('resource_2:delegationRequest')} -{' '}
            {selectedTab === 'consignee' ? 'Consignee' : 'Liner'}
          </>
        ) : (
          ''
        )}
      </Card.Header>

      <Card.Body>
        <div className='container'>
               <div className='col-lg-12 mb-1'>
                  <Form.Label style={{ color: '#f5822a' }}>
                    {t('resource_message:SSMNumberNotification')}
                  </Form.Label>
                </div>
          <Form onSubmit={onSubmit} autoComplete='off'>
            <div className='row'>
              <div className='col-lg-12'>

                {dataCountry === 'HK' && (
                  <div>
                    {t('resource_message:blanketDelegation')}
                  </div>
                )}

                <div className='row'>
                  <div className='col-lg-3 '>
                    <Form.Group controlId='BlanketDelegation.startCountry'>
                      <Form.Label>{t('resource_2:startPOL')}</Form.Label>
                      <div className='row'>
                        <div className='col-lg-12 mb-1'>
                          <Form.Control
                            size='sm'
                            {...register('startCountry')}
                            className={`fs--1 col-lg-12 mb-1 form-control ${
                              errors.startCountry ? 'is-invalid' : ''
                            }`}
                            type='text'
                            onChange={(e) =>
                              setValue(
                                'startCountry',
                                e.target.value.trim().toLocaleUpperCase()
                              )
                            }
                            value={watch('startCountry')}
                            maxLength={5}
                            placeholder={`Enter ${t('resource_2:startPOL')}`}
                            disabled={
                              selectedDataNominatedAgent?.standingInstructionId &&
                              selectedDataNominatedAgent?.standingInstructionId !=
                                null
                                ? true
                                : false
                            }
                          />
                          <div className='invalid-feedback'>
                            {errors.startCountry?.message?.toString()}
                          </div>
                        </div>
                        {/*  <div className='col-lg-6 mb-1'>
                      <Form.Control
                        size='sm'
                        {...register('startPort')}
                        className={`fs--1 col-lg-6 mb-1 form-control ${
                          errors.startPort ? 'is-invalid' : ''
                        }`}
                        type='text'
                        onChange={(e) => setStartCountry(e.target.value)}
                        value={startPort}
                      />
                      <div className='invalid-feedback'>
                        {errors.startPort?.message?.toString()}
                      </div>
                    </div> */}
                      </div>
                    </Form.Group>
                  </div>
                  <div className='col-lg-3 '>
                    <Form.Group controlId='BlanketDelegation.pol'>
                      <Form.Label>
                        {/* <span className='mandatory'>*</span> */}
                        {t('resource_1:pol')}
                      </Form.Label>
                      <div className='row'>
                        <div className='col-lg-12 mb-1'>
                          <Form.Control
                            size='sm'
                            {...register('polCountry')}
                            className={`fs--1 form-control ${
                              errors.polCountry ? 'is-invalid' : ''
                            }`}
                            type='text'
                            onChange={(e) =>
                              setValue(
                                'polCountry',
                                e.target.value.trim().toLocaleUpperCase()
                              )
                            }
                            value={watch('polCountry')}
                            maxLength={5}
                            placeholder={`Enter ${t('resource_1:pol')} `}
                            disabled={
                              selectedDataNominatedAgent?.standingInstructionId &&
                              selectedDataNominatedAgent?.standingInstructionId !=
                                null
                                ? true
                                : false
                            }
                          />
                          <div className='invalid-feedback'>
                            {errors.polCountry?.message?.toString()}
                          </div>
                        </div>
                        {/*  <div className='col-lg-6 mb-1'>
                      <Form.Control
                        size='sm'
                        {...register('polPort')}
                        className={`fs--1 form-control ${
                          errors.polPort ? 'is-invalid' : ''
                        }`}
                        type='text'
                        onChange={(e) => setPolPort(e.target.value)}
                        value={polPort}
                      />
                      <div className='invalid-feedback'>
                        {errors.polPort?.message?.toString()}
                      </div>
                    </div> */}
                      </div>
                    </Form.Group>
                  </div>

                  <div className='col-lg-3 '>
                    <Form.Group controlId='BlanketDelegation.pod'>
                      <Form.Label>
                        {/* <span className='mandatory'>*</span> */}
                        {t('resource_1:pod')}
                      </Form.Label>
                      <div className='row'>
                        <div className='col-lg-12 mb-1'>
                          <Form.Control
                            size='sm'
                            {...register('podCountry')}
                            className={`fs--1 form-control ${
                              errors.podCountry ? 'is-invalid' : ''
                            }`}
                            type='text'
                            onChange={(e) =>
                              setValue(
                                'podCountry',
                                e.target.value.trim().toLocaleUpperCase()
                              )
                            }
                            value={watch('podCountry')}
                            maxLength={5}
                            placeholder={`Enter ${t('resource_1:pod')}`}
                            disabled={
                              selectedDataNominatedAgent?.standingInstructionId &&
                              selectedDataNominatedAgent?.standingInstructionId !=
                                null
                                ? true
                                : false
                            }
                          />
                          <div className='invalid-feedback'>
                            {errors.podCountry?.message?.toString()}
                          </div>
                        </div>
                        {/*   <div className='col-lg-6 mb-1'>
                      <Form.Control
                        size='sm'
                        {...register('podPort')}
                        className={`fs--1 form-control ${
                          errors.podPort ? 'is-invalid' : ''
                        }`}
                        type='text'
                        onChange={(e) => setPodPort(e.target.value)}
                        value={podPort}
                      />
                      <div className='invalid-feedback'>
                        {errors.podPort?.message?.toString()}
                      </div>
                    </div> */}
                      </div>
                    </Form.Group>
                  </div>
                  <div className='col-lg-3 '>
                    <Form.Group controlId='BlanketDelegation.endCountry'>
                      <Form.Label> {t('resource_2:fPODend')}</Form.Label>
                      <div className='row'>
                        <div className='col-lg-12 mb-1'>
                          <Form.Control
                            size='sm'
                            {...register('endCountry')}
                            className={`fs--1 form-control ${
                              errors.endCountry ? 'is-invalid' : ''
                            }`}
                            type='text'
                            onChange={(e) =>
                              setValue(
                                'endCountry',
                                e.target.value.trim().toLocaleUpperCase()
                              )
                            }
                            value={watch('endCountry')}
                            maxLength={5}
                            placeholder={`Enter ${t('resource_2:fPODend')}`}
                            disabled={
                              selectedDataNominatedAgent?.standingInstructionId &&
                              selectedDataNominatedAgent?.standingInstructionId !=
                                null
                                ? true
                                : false
                            }
                          />
                          <div className='invalid-feedback'>
                            {errors.endCountry?.message?.toString()}
                          </div>
                        </div>
                        {/* <div className='col-lg-6 mb-1'>
                      <Form.Control
                        size='sm'
                        {...register('endPort')}
                        className={`fs--1 form-control ${
                          errors.endPort ? 'is-invalid' : ''
                        }`}
                        type='text'
                        onChange={(e) => setEndPort(e.target.value)}
                        value={endPort}
                      />
                      <div className='invalid-feedback'>
                        {errors.endPort?.message?.toString()}
                      </div>
                    </div> */}
                      </div>
                    </Form.Group>
                  </div>
                  {watch('polCountry') == '' &&
                  watch('podCountry') == '' &&
                  watch('startCountry') == '' &&
                  watch('endCountry') == '' ? (
                    <p
                      style={{
                        margin: '0px',
                        color: 'red',
                        fontSize: '12px',
                        textAlign: 'center',
                      }}>
                      {' '}
                      Please fill the at least one field{' '}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              {/*  <div className='col-lg-3 ' style={{ zIndex: 501 }}>
                <Form.Group controlId='BlanketDelegation.cargoType'>
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_2:cargoType')}
                  </Form.Label>

                  <MultiSelect
                    {...register('cargoType')}
                    className={`fs--1  ${errors.cargoType ? 'is-invalid' : ''}`}
                    options={options}
                    value={cargoType}
                    onChange={(e: any) => {
                      setCargoType(e);
                      if (e.length == 0) {
                        setValue('cargoType', null);
                      } else {
                        setValue('cargoType', e);
                      }
                    }}
                    labelledBy='Select'
                    disabled={
                      selectedDataNominatedAgent?.standingInstructionId &&
                      selectedDataNominatedAgent?.standingInstructionId != null
                        ? true
                        : false
                    }
                  />

                  <div className='invalid-feedback'>
                    {errors.cargoType?.message?.toString()}
                  </div>
                </Form.Group>
              </div> */}
              <div className='col-lg-3 mb-3' style={{ zIndex: 501 }}>
                <Form.Group controlId='BlanketDelegation.validForm'>
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_2:validForm')}{' '}
                  </Form.Label>
                  <div>
                    <>
                      <DatePicker
                        selected={watch('validForm')}
                        className={`fs--1 form-control ${
                          errors.validForm ? 'is-invalid' : ''
                        }`}
                        onChange={(date) => {
                          setValue('validForm', date);
                        }}
                        timeIntervals={5}
                        dateFormat={getDateFormat()}
                        isClearable={clearable}
                        popperProps={{
                          strategy: 'fixed',
                        }}
                        minDate={
                          currentDate
                            ? new Date(currentDate.getTime() + 86400000)
                            : new Date()
                        }
                        placeholderText='Select Date '
                        //disabled={DatePickDisabled}
                        //value={value}
                        disabled={
                          selectedDataNominatedAgent?.standingInstructionId &&
                          selectedDataNominatedAgent?.standingInstructionId !=
                            null
                            ? true
                            : false
                        }
                      />
                    </>
                  </div>
                </Form.Group>
              </div>

              <div className='col-lg-3 mb-3' style={{ zIndex: 501 }}>
                <Form.Group controlId='BlanketDelegation.validTill'>
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_2:validTill')}{' '}
                  </Form.Label>

                  <DatePicker
                    selected={watch('validTill')}
                    {...register('validTill')}
                    className={`fs--1 form-control ${
                      errors.validTill ? 'is-invalid' : ''
                    }`}
                    onChange={(date: any) => {
                      setValue('validTill', date);
                    }}
                    timeIntervals={5}
                    dateFormat={getDateFormat()}
                    isClearable={clearable}
                    popperProps={{
                      strategy: 'fixed',
                    }}
                    minDate={
                      watch('validForm')
                        ? new Date(watch('validForm').getTime() + 86400000)
                        : new Date()
                    }
                    placeholderText='Select Date'
                    disabled={disableFields}
                  />

                  <>
                    <div
                      className='text-danger mt-1'
                      style={{ fontSize: '12px' }}>
                      {errors.validTill?.message?.toString()}
                    </div>
                  </>
                </Form.Group>
              </div>

              {/*  <div className='col-lg-3 '>
                <Form.Group controlId='user.status'>
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {'Status'}
                  </Form.Label>
                  <Form.Select
                    {...register('status')}
                    className={`fs--1 form-control ${
                      errors.status ? 'is-invalid' : ''
                    }`}
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value=''>Select</option>
                    <option value='1'>Active</option>
                    <option value='2'>Inactive</option>
                  </Form.Select>
                  <div className='invalid-feedback'>
                    {errors.status?.message?.toString()}
                  </div>
                </Form.Group>
              </div> */}

              {disableFields === true &&
              selectedDataNominatedAgent?.agentNominationDetails?.fileName ? (
                <div className='col-lg-12 mb-3'>
                  <Form.Label className='fs--1'>
                    {profileDetails.partnerCountry === 'ZA' || profileDetails.partnerCountry == 'JO' ? (
                      <>
                        {'LOI Upload'} {' : '}
                      </>
                    ) : (
                      ''
                    )}
                  </Form.Label>

                  <span
                    className='text-primary cursor-pointer'
                    onClick={() => {
                      window.open(
                        `${
                          process.env.REACT_APP_CLIENT_APP_HOST
                        }/api/edo-rest-v2/v2/common/openDocument?id=${parseInt(
                          docId
                        )}&moduleTp=AGENT_NOMINATION&linerId=${
                          profileDetails?.partnerType === 'liner'
                            ? profileDetails?.partnerId
                            : selectedLinerId
                        }`,
                        '_blank'
                      );
                    }}>
                    {fileName}
                  </span>
                </div>
              ) : (
                ''
              )}

              {!matchPth ? (
                ''
              ) : (
                <div className='col-lg-6 mb-2'>
                  <Form.Group controlId='reqForm.remarks'>
                    <Form.Label>
                      {'Approval ' + t('resource_1:remark')}
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      value={state.rejectRemark}
                      onChange={(e) =>
                        setState({ ...state, rejectRemark: e.target.value })
                      }
                      disabled={
                        selectedDataNominatedAgent?.standingInstructionId &&
                        selectedDataNominatedAgent?.standingInstructionId !=
                          null
                          ? true
                          : false
                      }
                    />
                  </Form.Group>
                </div>
              )}
              {agentAccessList && agentAccessList.length > 0 ? (
                <div
                  className='col-lg-12 mt-2 mb-2 demmurageTable'
                  style={{ zIndex: 2 }}>
                  {/*   <span className='badge bg-secondary mb-2'>
                    {"Grant / invoice Access"}
                  </span> */}
                  <DataTable
                    data={agentAccessList}
                    gridName='BL_ACCESS'
                    columns={blAccessColumns}
                    sortable={false}
                    searchable={false}
                    showColumnFilter={false}
                    selection={false}
                    perPage={10}
                    pagination={false}
                    showExport={false}
                    uniqueFilterKey='blAccess'
                    autoHeight={true}
                    staticHiddenCols={['agentAccessId', 'agentNominationId']}
                  />
                </div>
              ) : (
                ''
              )}

              <div className='col-lg-6 mb-3'>
                <Form.Group controlId='tableComponent.agentPartnerId'>
                  <Form.Control
                    type='hidden'
                    value={agentPartnerId}
                    {...register('agentPartnerId')}></Form.Control>
                </Form.Group>
                <Form.Group controlId='tableComponent.agentNominationId'>
                  <Form.Control
                    type='hidden'
                    value={agentNominationId}
                    {...register('agentNominationId')}></Form.Control>
                </Form.Group>
                <Form.Group controlId='tableComponent.agentTaxId'>
                  <Form.Label>
                    <span className='mandatory'>*</span>
                    {dataCountry === 'SG'
                      ? t('resource_2:CompanyUEN')
                      : t('resource_2:taxId')}
                  </Form.Label>{' '}
                  <InputMask
                    disabled={
                      selectedDataNominatedAgent?.standingInstructionId &&
                      selectedDataNominatedAgent?.standingInstructionId != null
                        ? true
                        : false
                    }
                    {...register('taxId')}
                    className={`fs--1 form-control form-control-sm ${
                      errors.taxId ? 'is-invalid' : ''
                    }`}
                    type='text'
                    mask={taxIdFormat}
                    onChange={(e: any) => setTaxId(e.target.value)}
                    value={taxId}
                    onBlur={(e: any) => {
                      if (taxId !== '') {
                        //alert(country);
                        dispatch(
                          fetchCompanyDetailsForAgentDelegation({
                            taxId: taxId.trim(),
                            country: profileDetails?.partnerCountry,
                          })
                        );
                      } else {
                        setCompanyName('');
                        setValue('companyName', '');
                        setAddress('');
                        setValue('address', '');
                        setEmail('');
                        setValue('email', '');
                        setAgentPartnerId('');
                        setValue('agentPartnerId', '');
                      }
                      setValue('taxId', e.target.value.trim());
                      setTaxId(e.target.value.trim());
                    }}
                  />
                  <div className='invalid-feedback'>
                    {errors.taxId?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              <div className='col-lg-6 mb-3'>
                <Form.Group controlId='tableComponent.companyName'>
                  <Form.Label>
                    <span className='mandatory'>*</span>
                    {t('resource_2:companyName')}
                  </Form.Label>
                  <Form.Control
                    disabled={true}
                    size='sm'
                    {...register('companyName')}
                    className={`fs--1 form-control ${
                      errors.companyName ? 'is-invalid' : ''
                    }`}
                    type='text'
                    onChange={(e) => setCompanyName(e.target.value)}
                    onBlur={(e) => {
                      setValue('companyName', e.target.value);
                      trigger('companyName');
                    }}
                    // value={
                    //   !matchPathInstruction()
                    //     ? selectedDataNominatedAgent?.agentNominationDetails.agentPartnerName || ''
                    //     : companyName
                    // }
                    value={companyName}
                  />
                  <div className='invalid-feedback'>
                    {errors.companyName?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              <div className='col-lg-6 mb-3'>
                <Form.Group controlId='tableComponent.registeredEmail'>
                  <Form.Label>
                    <span className='mandatory'>*</span>
                    {t('resource_2:emailAddress')}
                  </Form.Label>
                  <Form.Control
                    size='sm'
                    {...register('email')}
                    className={`fs--1 form-control ${
                      errors.email ? 'is-invalid' : ''
                    }`}
                    type='email'
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={(e) => {
                      setValue('email', e.target.value);
                      trigger('email');
                    }}
                    disabled={
                      selectedDataNominatedAgent?.standingInstructionId &&
                      selectedDataNominatedAgent?.standingInstructionId != null
                        ? true
                        : false
                    }
                    // value={
                    //   !matchPathInstruction()
                    //     ? selectedDataNominatedAgent?.agentNominationDetails.agentEmailId || ''
                    //     : email
                    // }
                    value={email}
                  />
                  <div className='invalid-feedback'>
                    {errors.email?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              <div className='col-lg-6 mb-3'>
                <Form.Group
                  style={{ position: 'relative' }}
                  controlId='tableComponent.address'>
                  <Form.Label>
                    <span className='mandatory'>*</span>
                    {t('resource_1:address')}
                  </Form.Label>
                  <Form.Control
                    {...register('address')}
                    className={`fs--1 form-control ${
                      errors.address ? 'is-invalid' : ''
                    }`}
                    as='textarea'
                    onChange={(e) => {
                      dispatch(updateSelectedAddressResult(''));
                      setAddress(e.target.value);
                      _onAddressChange(e.target.value);
                    }}
                    onBlur={(e) => {
                      setValue('address', e.target.value);
                      trigger('address');
                    }}
                    disabled={
                      true
                      
                    }
                    // value={
                    //   !matchPathInstruction()
                    //     ? selectedDataNominatedAgent?.agentNominationDetails.address || ''
                    //     : address
                    // }
                    value={address}
                  />
                  <div className='invalid-feedback'>
                    {errors.address?.message?.toString()}
                  </div>

                  {address &&
                  !selectedAddressResult &&
                  !isAddressSuggesstionLoading &&
                  registrationAddressSuggesstion?.length > 0 ? (
                    <div
                      style={{ position: 'absolute' }}
                      className='w-100 rounded border bg-white  odex-registration-address d-flex flex-column justify-content-start align-items-start'>
                      {registrationAddressSuggesstion.map((v) => (
                        <div
                          className='p-2 d-flex flex-row justify-content-start align-items-center fs--1 cursor-pointer'
                          key={v.placeId}
                          onClick={() => {
                            dispatch(updateSelectedAddressResult(v.text));
                            setAddress(v.text);
                          }}>
                          <FontAwesomeIcon
                            icon={faLocation}
                            className='text-primary me-2'
                          />
                          <span>{v.text}</span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </div>
              {/*   {
                <div className='col-lg-6 mb-2'>
                  <Form.Group controlId='reqForm.remarks'>
                    <Form.Label>
                      {'Customer ' + t('resource_1:remark')}
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      value={state.customerRemarks}
                      onChange={(e) =>
                        setState({ ...state, customerRemarks: e.target.value })
                      }
                      disabled={!!matchPth}
                    />
                  </Form.Group>
                </div>
              } */}
              {(dataCountry !== 'US') &&
              (!disableFields ||
                (profileDetails.partnerType === 'customer' &&
                  agentNominationId !== profileDetails.partnerId &&
                  agentPartnerId != profileDetails.partnerId &&
                  status == 'Submitted')) ? (
                <div className='col-lg-12 mb-2'>
                  <Form.Group>
                    <Form.Check
                      type='checkbox'
                      label={
                        <Form.Label className='fs--1'>
                          <span>
                            I accept Terms & Conditions to delegate the above
                            selected party
                          </span>
                        </Form.Label>
                      }
                      onClick={showTncDialog}
                      checked={termsAccepted}
                      //onChange={onToggleGrantFrtInvAccess}
                      //disabled={disableFields}
                    />
                  </Form.Group>
                </div>
              ) : (
                <></>
              )}
              {agentNominationId != 0 && disableFields ? (
                <div className='col-lg-6 mb-2'>
                  <Form.Group>
                    <Form.Label>{t('resource_1:status')}</Form.Label>
                    <Form.Select
                      className='fs--1'
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                        setValue('status', e.target.value);
                      }}
                      disabled={
                        selectedDataNominatedAgent?.standingInstructionId &&
                        selectedDataNominatedAgent?.standingInstructionId !=
                          null
                          ? true
                          : false
                      }>
                      <option
                        value='Completed'
                        selected={status == 'Completed' ? true : false}>
                        Completed
                      </option>
                      <option
                        value='Submitted'
                        selected={status == 'Submitted' ? true : false}>
                        Submitted
                      </option>
                      <option
                        value='Rejected'
                        selected={status == 'Rejected' ? true : false}>
                        Rejected
                      </option>
                      <option
                        value='Cancelled'
                        selected={status == 'Cancelled' ? true : false}>
                        Cancelled
                      </option>
                    </Form.Select>
                  </Form.Group>
                </div>
              ) : (
                <></>
              )}

              {/*  {(profileDetails.partnerCountry == 'SG' ||
                profileDetails.partnerCountry == 'ZA') &&
              !disableFields ? (
                <div className='col-lg-12 mb-2'>
                  <Form.Group>
                    <Form.Check
                      type='checkbox'
                      label={
                        <Form.Label className='fs--1'>
                          <span>
                            I accept Terms & Conditions to delegate the above
                            selected party
                          </span>
                        </Form.Label>
                      }
                      onClick={showTncDialog}
                      checked={termsAccepted}
                      //onChange={onToggleGrantFrtInvAccess}
                      //disabled={disableFields}
                    />
                  </Form.Group>
                </div>
              ) : (
                <></>
              )} */}

              {false &&
              ((rbac.rbacDetails.agentNominationApproveReject === 'Y' &&
                profileDetails.partnerType === 'liner' &&
                data?.agentNominationDetails?.country === dataCountry &&
                selectedDataNominatedAgent?.agentNominationDetails
                  ?.requestType &&
                (selectedDataNominatedAgent?.agentNominationDetails
                  ?.requestType === 811 ||
                  selectedDataNominatedAgent?.agentNominationDetails
                    ?.requestType === 814)) ||
                (profileDetails.partnerType === 'customer' &&
                  agentNominationId !== profileDetails.partnerId &&
                  selectedDataNominatedAgent?.agentNominationDetails
                    ?.requestType &&
                  (selectedDataNominatedAgent?.agentNominationDetails
                    ?.requestType === 805 ||
                    selectedDataNominatedAgent?.agentNominationDetails
                      ?.requestType === 812 ||
                    selectedDataNominatedAgent?.agentNominationDetails
                      ?.requestType === 813 ||
                    selectedDataNominatedAgent?.agentNominationDetails
                      ?.requestType === 815) &&
                  agentPartnerId != profileDetails.partnerId))
                ? !instructionList
                    .filter((v: any) => docId && v?.docId === parseInt(docId))
                    .map(
                      (u: any) =>
                        u?.event === 'Completed' || u?.event === 'Rejected'
                    )
                    .includes(true) && (
                    <div className='d-flex justify-content-center align-items-center'>
                      <Button
                        disabled={
                          data?.status === 'Completed' ||
                          data?.status === 'Rejected'
                            ? disableFields
                            : false
                        }
                        onClick={(e) => {
                          onApproveReject('A', 'AGENT_NOMINATION');
                        }}
                        variant='success'
                        className='me-4 approve'
                        size='sm'>
                        <FontAwesomeIcon
                          icon={faCheck}
                          className='me-2 approve'
                        />
                        {t('resource_1:approve')}
                      </Button>

                      <Button
                        disabled={
                          data?.status === 'Completed' ||
                          data?.status === 'Rejected'
                            ? disableFields
                            : false
                        }
                        onClick={(e) => {
                          onApproveReject('R', 'AGENT_NOMINATION');
                        }}
                        variant='danger reject'
                        size='sm'>
                        <FontAwesomeIcon
                          icon={faTimes}
                          className='me-2 reject'
                        />
                        {t('resource_1:reject')}
                      </Button>
                    </div>
                  )
                : !matchPth &&
                  disableFields == false &&
                  !instructionList
                    .filter((v: any) => docId && v?.docId === parseInt(docId))
                    .map(
                      (u: any) =>
                        u?.event === 'Completed' || u?.event === 'Rejected'
                    )
                    .includes(true) && (
                    <div className='col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center'>
                      <Button
                        variant='falcon-primary me-2'
                        size='sm'
                        type='submit'
                        disabled={
                          !termsAccepted
                            ? true
                            : watch('polCountry') == '' &&
                              watch('podCountry') == '' &&
                              watch('startCountry') == '' &&
                              watch('endCountry') == ''
                            ? true
                            : false

                          /* profileDetails.partnerCountry == 'US'
                            ? !agentPartnerId
                            : profileDetails.partnerCountry == 'SG' ||
                              profileDetails.partnerCountry == 'ZA'
                            ? !termsAccepted || !agentPartnerId
                            : !agentPartnerId */
                        }>
                        <FontAwesomeIcon icon={faSave} className='me-2' />
                        {t('resource_1:submit')}
                      </Button>

                      <Button
                        variant='falcon-danger'
                        onClick={() => {
                          dispatch(clearFields({}));
                          resetForm();
                          formReset();
                          dispatch(toggleAddAgent(false));
                          dispatch(toggleNominationReq(false));
                          dispatch(updateSelectedTab(''));
                          dispatch(toggleGrantFrtInvAccess(false));
                          setTermsAccepted(dataCountry == 'US');
                        }}
                        size='sm'>
                        <FontAwesomeIcon icon={faRedo} className='me-2' />
                        {t('resource_1:reset')}
                      </Button>
                    </div>
                  )}
              {disableFields == true &&
              status !== 'Cancelled' &&
              selectedDataNominatedAgent?.agentNominationDetails
                ?.requestedPartnerId === profileDetails.partnerId &&
              rbac.rbacDetails.nominactionCUD == 'Y' ? (
                <div className='col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center'>
                  <Button
                    variant='falcon-primary me-2'
                    size='sm'
                    type='button'
                    disabled={status === 'Completed' ? !disableFields : false}
                    onClick={() => {
                      dispatch(toggleDisableFields(false));
                      //
                    }}>
                    <FontAwesomeIcon icon={faEdit} className='me-2' />
                    {t('resource_1:edit')}
                  </Button>
                  {
                    <Button
                      variant='falcon-danger'
                      size='sm'
                      type='button'
                      disabled={status === 'Completed' ? !disableFields : true}
                      onClick={(e) => {
                        onApproveReject('C', 'BLANKET_INSTRUCTION');
                      }}>
                      <FontAwesomeIcon icon={faCancel} className='me-2' />
                      {t('resource_2:cancelDelegation')}
                    </Button>
                  }
                </div>
              ) : disableFields === false &&
                selectedDataNominatedAgent?.standingInstructionId &&
                selectedDataNominatedAgent?.standingInstructionId != null &&
                rbac.rbacDetails.nominactionCUD == 'Y' ? (
                <div className='col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center'>
                  <Button
                    variant='falcon-primary me-2'
                    size='sm'
                    disabled={!termsAccepted}
                    onClick={onSubmit}>
                    <FontAwesomeIcon icon={faSave} className='me-2' />
                    {t('resource_1:submit')}
                  </Button>

                  <Button
                    variant='falcon-danger'
                    onClick={_onCloseForm}
                    size='sm'>
                    <FontAwesomeIcon icon={faRedo} className='me-2' />
                    {t('resource_1:cancel')}
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Form>
          {
            <Modal show={tncDialog} onHide={closeTncDialog} size='lg'>
              <Modal.Header className='p-2 pb-1'>
                <Modal.Title>{t('resource_1:disclaimer')}</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  overflow: 'auto',
                  fontSize: '12px',
                }}>
                <AgentDelegationTNC />
                <div className='col-lg-8 offset-2 mt-3 d-flex justify-content-center align-items-center'>
                  <Button
                    variant='falcon-primary'
                    size='sm'
                    className='me-2'
                    onClick={() => {
                      setTermsAccepted(true);
                      setShowTncDialog(false);
                    }}>
                    <FontAwesomeIcon icon={faSave} className='me-2' />
                    {t('resource_1:agree')}
                  </Button>

                  <Button
                    variant='falcon-danger'
                    size='sm'
                    onClick={() => {
                      setTermsAccepted(false);
                      setShowTncDialog(false);
                    }}>
                    <FontAwesomeIcon icon={faTimes} className='me-2' />
                    {t('resource_1:disagree')}
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          }
        </div>
      </Card.Body>
    </Card>
  );
};

export default BlanketDelegation;
