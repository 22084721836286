import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import DashboardContainer from '../../../components/common/DashboardContainer';
import FilterView from '../../common/components/FilterView';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toggleColumnSettingsEmailDash, updateSelectedRow } from '../emailDashboard/emailDashboardSlice';
import { updateCurrentPageEmailDashboard } from '../dashboard/slices/dashboardSlice';
import { IDataTableColumn } from '../../common/types/dataTable';
import useDataCountry from '../../../hooks/useDataCountry';
import { getColumnViews } from '../../common/actions/dataTable';
import {
  updateDashbaordRefresh,
  updateParams,
  updateSortStateDataTable,
} from '../../common/components/DataTable/slices/DataTable';
import {
  IConfigsDefinition,
} from '../../utils/columnConfig';
import { useGenerateColumnConfig } from '../../../hooks/useGenerateColumnConfig';
import { AESDecrypt, AESEncrypt } from '../../../encrypt-util';
import { updateDashbaordName } from '../../common/slices/navbarTop';
import moment from 'moment';
import { getDashboardData } from '../dashboard/actions/dashboard';
import queryString from 'query-string';





import DataTable from '../../common/components/DataTable';

const gridName = 'EMAIL_LOGS'//invoice
const dashboardName = 'EMAIL_TEMPLATE_DASHBOARD'//inv

const EmailDashboard = () => {

  const { blNum } = useParams();
  


  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  
  const data = useSelector(
    (state: RootState) => state.dashboard.emailDashboardList
  );

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const grids = useSelector(
    (state: RootState) => state.dataTable.emailGrids
  );

  const refresh = useSelector((
    state:RootState)=> state.dataTable.refreshDashboard)

  const sortState = useSelector((state: RootState) => state.dataTable.sortState);

  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageEmailDashboard(page));
    //
    //
    //
  };


  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });

  const dataCountry = useDataCountry()
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);

  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPageEmail
  );
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );

  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: grids?.[0]?.gridId,
  });
  const [currentState, setCurrentState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: grids?.[0]?.gridId,
  });


  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );

  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );

  const emailTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.emailTopSearchState
  );

  function useDebouncedEffect(effect: any, deps: any, delay: any) {
    const callback = useRef<any>();

    useEffect(() => {
      callback.current = effect;
    }, [effect]);

    useEffect(() => {
      const handler = () => {
        if (callback.current) {
          callback.current();
        }
      };

      const timer = setTimeout(handler, delay);

      return () => clearTimeout(timer);
    }, [...deps, delay]);
  }

  const generateColumnConfig = useGenerateColumnConfig()

  const getParams = () => {
    return {
      blid: blNum ? AESDecrypt(blNum) : '',
      pmtmode: blNum ? AESDecrypt(blNum) : '',
    };
  };

  useEffect(() => {
    const emailDashboardDefinition =
      grids?.[0]?.columnConfigGrid || '';

    if (!emailDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(emailDashboardDefinition)),
        getParams,
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [grids, location]);

  useEffect(() => {
    dispatch(updateDashbaordName(gridName));
  }, []);

  const showColumnSettings = useSelector(
    (state: RootState) => state.emailDashboard.showColumnSettings)

  useEffect(() => {
    if (location?.pathname && dataCountry && linerId) {
      dispatch(
        getColumnViews({ gridName: dashboardName, country: dataCountry, linerId: linerId })
      );
    }
    dispatch(updateParams({}));
    // dispatch(
    //   updateSortStateDataTable({
    //     key: null,
    //   })
    // );
  }, [dataCountry, linerId]);

  const filterStateLocal = filterState?.['emailDashboard'];

  const sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(',')
    ?.join?.(' ');


  useEffect(() => {
    const currentState = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids?.[0]?.gridId,
    };
    if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
      setCurrentState({
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: grids?.[0]?.gridId,
      });
    }
  }, [filterStateLocal, location.search, grids]);





  useEffect(() => {
    loadEmailDashboard(false);
  }, [currentPage]);
  
  useEffect(() => {
    if (refresh) loadEmailDashboard(false)
    // server sometimes takes time to update the view so the delay
    setTimeout(() => dispatch(updateDashbaordRefresh(false)), 2000)
  }, [refresh])

  // useEffect(() => {
  //   if (matchPath(location.pathname, '/emailDashboard')) loadEmailDashboard(false);
  // }, [location.pathname]);



  // useEffect(() => {
  //   if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
  //     loadPaymentDashboard(true);
  //     setPreviousState(currentState);
  //   }
  // }, [currentState, linerId]);

  useDebouncedEffect(() => {
    if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
      loadEmailDashboard(true);
      setPreviousState(currentState);
    }
  }, [currentState, linerId], 700);

  useEffect(() => {
    loadEmailDashboard(true);
  }, [linerId]);




  const loadEmailDashboard = (filterUpdated: boolean) => {
    if (filterUpdated) setCurrentPage(1);

    if (filterStateLocal) {
      
      if (Object.keys(filterStateLocal)?.length > 0) {
        
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = {};
          const currentDate = new Date();

          if (grids[0]) {
            let timeFlag = '';
            let timeDiff = 0;
            let dateRange: any = {};

            const newdata = grids[0].configGroup?.find(
              (c: any) => c.userGridId == selectedConfigId?.['emailDashboard']
            )?.columnConfigGrid;
            if (newdata) {
              timeFlag = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.filterState;
              timeDiff = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.timeDiff;
              dateRange = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.dateRange;
            }
            if (filterApiState.invdt && timeFlag == 'day' && dateRange) {
              filterApiState.invdt[1] = moment()
                .subtract(Math.abs(dateRange.to), 'days')
                .endOf('day')
                .toISOString()
              filterApiState.invdt[0] = moment()
                .subtract(Math.abs(dateRange.from), 'days')
                .startOf('day')
                .toISOString();
            }
          }
          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }
          if (
            (profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              grids?.[0]?.whereClause) ||
            profileDetails.partnerType === 'liner'
          ) {
            setTimeout(() => {
              dispatch(
                getDashboardData({
                  gridName: gridName,
                  payload: {
                    fields: /*  SelectedViewFields?.fields || */[],
                    filters:
                      !selectedTopSearchFilter ||
                        selectedTopSearchFilter?.where == '' ||
                        searchInputValue.trim() === ''
                        ? finalFilterApiState
                        : {},

                    size: 20,
                    sorts: sortState && sortState?.key != null && sortState?.value != null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : ['email_logs_id desc'],
                    page: filterUpdated ? 1 : currentPage,
                  },
                  dataCountry: dataCountry,
                  partnerId:
                    profileDetails.partnerType === 'customer'
                      ? profileDetails.partnerId
                      : null,
                  where: grids?.[0]?.whereClause
                    ? AESEncrypt(grids?.[0]?.whereClause)
                    : null,
                  parameter:
                    selectedTopSearchFilter?.where !== '' &&
                      searchInputValue.trim() !== ''
                      ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                      : {},
                })
              );
            }, 250)

          }
        }
      } else {

        console.log("t", profileDetails?.partnerType)

        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            grids?.[0]?.whereClause) ||
          // profileDetails?.partnerType === 'liner'
          ///////////////////////////////////////////
          profileDetails?.partnerType === 'customer'
        ) {
          console.log("jash6")
          setTimeout(() => {
            
            dispatch(
              getDashboardData({
                gridName: gridName,
                payload: {
                  fields: /* SelectedViewFields?.fields || */[],
                  filters:
                    selectedTopSearchFilter?.where === ''
                      ? emailTopSearchState
                      : {},
                  size: 20,
                  sorts: sortState && sortState?.key != null && sortState?.value != null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : ['email_logs_id desc'],
                  page: filterUpdated ? 1 : currentPage,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === 'customer'
                    ? profileDetails.partnerId
                    : null,
                where: grids?.[0]?.whereClause
                  ? AESEncrypt(grids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ''
                    ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                    : {},
              })
            );
          }, 250)

        }
      }
    } else {
      
      if (
        location.pathname.includes('/emailDashboard') &&
        location?.state?.from == undefined
      ) {
        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            grids?.[0]?.whereClause) ||
          profileDetails.partnerType === 'liner'
        ) {
          setTimeout(() => {
            dispatch(
              getDashboardData({
                gridName: gridName,
                payload: {
                  fields: /* SelectedViewFields?.fields || */[],
                  filters:
                    selectedTopSearchFilter?.where == ''
                      ? emailTopSearchState
                      : {},
                  size: 20,
                  sorts: sortState && sortState?.key != null && sortState?.value != null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : ['email_logs_id desc'],
                  page: filterUpdated ? 1 : currentPage,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === 'customer'
                    ? profileDetails.partnerId
                    : null,
                where: grids?.[0]?.whereClause
                  ? AESEncrypt(grids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ''
                    ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                    : {},
              })
            );
          }, 250)
        }
      }
    }
  };

  useEffect(() => {
    dispatch(updateParams({}));
    dispatch(
      updateSortStateDataTable({
        key: null,
      })
    );
  }, [dataCountry]);

    const sideWidth = useMemo(
      () =>
        Object.entries({
          [`/emailDashboard`]: 0,          
        }).find(([path, _]) => matchPath(path, location.pathname))?.[1] ?? 9,
      [location.pathname]
    );

  return (
    <DashboardContainer
    sideWidth={sideWidth}
    >
      <FilterView
        loadList={loadEmailDashboard}
        showUploadButton
        showExport={true}
        showColumnSettings={true}
        dataLoading={false}
        toggleColumnSettings={toggleColumnSettingsEmailDash}
        columnSettingsPersistKey='@odex/emailDashboard/v1'
        uniqueFilterKey='emailDashboard'
        configViews={
          grids?.find?.((g) => g?.gridName === dashboardName)
            ?.configGroup || []
        }
        showRightDiv={!!matchPath(location.pathname, '/emailDashboard')}
        dashboardName={dashboardName}
        data={data}
        gridDef={grids}
      />
      {columns?.length > 0 && (
        <DataTable
          data={data?.result || null}
          columns={columns}
          dataCountry={dataCountry || ''}
          sortable
          searchable={false}
          showColumnFilter={false}
          selection={false}
          perPage={20}
          pagination
          numberPagination
          maxPage={parseInt(data?.maxPage || '100')}
          hasNext={data?.hasNext}
          currentPage={currentPage}
          gridId={grids?.[0]?.gridId || 0}
          onChangePageNumber={(pageNumber) =>
            setCurrentPage(pageNumber)
          }
          showExport={false}
          showColumnSettings={showColumnSettings}
          onHide={() => { dispatch(toggleColumnSettingsEmailDash(false)); }}
          columnSettingsPersistKey='@odex/emailDashboard/v1'
          customSort={true}
          uniqueFilterKey='emailDashboard'
          staticHiddenCols={configs?.staticHiddenCols || []}
          defaultHiddenCols={[]}
          tableMinHeight={400}
          gridName={dashboardName}
          dataLoading={false}
          configGroup={grids?.[0]?.configGroup || []}
        />
      )}

    </DashboardContainer>
  )
};

export default EmailDashboard;
