import { ChangeEvent, useEffect, useRef, useState } from "react";

import Lottie from "lottie-react";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import CreditBalance from "./CreditBalance";
import PayableAmount from "./PayableAmount";
import App from "./app";
import infiniteLoop from '../../../assets/img/animated-icons/infinite-loop.json';


import {
  paymentLoader,
  resetPaymentLoaders,
  setEditPayment,
  setPaymentStatus,
  updatePaymentMode,
  updatePymtData,
} from "./slices/blPaymentSlice";
import {
  toggleDocumentHistoryColumnShort,
} from "../blDetails/slices/documentHistorySlice";
import { updateBlDetailsRowColumns } from "../blDetails/slices/blDetailsTable";
import {
  faCancel,
  faCheck,
  faEdit,
  faEraser,
  faInfoCircle,
  faRedo,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { enableBlPayment } from "../standingInstruction/slices/standingInstructionTable";
import {
  acceptPayment,
  addInvoiceToExistingPayment,
  cancelPayment,
  checkDuplicateReferenceNo,
  checkDuplicateReferenceNoByPymtId,
  editBankPymtRefNo,
  getPaymentBanks,
  getPymtConfDetails,
  getPymtDetailsByPymtNo,
  getPymtModeOptions,
  getPymtRefNo,
  getTransactionDetails,
  rejectPayment,
  submitAutoPayment,
  submitPaymentAddFund,
  submitPaymentBanks,
  updatePaymentAttachment,
  updatePaymentReferenceNo,
} from "./actions/payments";
import { getCreditBalance } from "../blRequestInstruction/components/actions/Instruction";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { defineCustomElements, PaycargoCheckout } from "@paycargo/js-react";

import PayPalCard from "./CardPayment";
import axiosInstance from "../../../axiosSetup";
import UploadIndicator from "../../../components/common/UploadIndicator";
import { useAmountFormatter } from "../../../hooks/useAmountFormatter";
import PaymentRefUserGuid from "./PaymentRefUserGuide";
import useCDNCountry from "../../../hooks/useCDNCountry";
import { getMultiCountryAllowance } from "./actions/payments";
import ToggleDiv from "../../../components/common/ToggleDiv";
import DataTable from "../../common/components/DataTable";
import { IDataTableColumn } from "../../common/types/dataTable";
import VideoPopup from "../videoPopup/videoPopup";
import { AESDecrypt } from "../../../encrypt-util";
import { Button, Card, Modal } from "react-bootstrap";
import { selectedPmtNumber } from "./slices/PaymentInvoiceSlice";
import { updateDashbaordRefresh, updateSortStateDataTable } from "../../common/components/DataTable/slices/DataTable";
import { updateSelectedAmount, updateSelectedRowsInvoice } from "../multiBlPayment/slices/multiBlPaymentSlice";
import { Form } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import PaymentInvoiceList from "./PaymentInvoiceList";
import { StandaloneInvoiceDetails } from "./slices/standaloneInvoicePaymentSlice";
import { toggleMainLayout } from "../settings/slices/themeSettings";
import { getAppConfig } from "../../common/actions/userDetails";
import { useBackNavigation } from "../../../hooks/BackNavigation";

defineCustomElements();
const BlPayment = () => {
  const { t } = useTranslation();
  const [selectedCcy, setSelectedCcy] = useState("USD");
  const [paymentAmount, setPaymentAmount] = useState("");

  var pathname = window.location.pathname;
  var pathArray = pathname.split("/");
  var lastEndpoint = pathArray[pathArray.length - 1];
  const paymentMode = useSelector(
    (state: RootState) => state.blPayment.paymentMode
  );
  const paymentTransactionDetails = useSelector(
    (state: RootState) => state?.blPayment?.paymentTransactionDetails
  )
  const unpaidData = useSelector((state: RootState) =>
    state.invoiceUnpaid.data.filter((item) => !!item.ccy && item.ccy !== "")
  );
  const standaloneInvoiceList = useSelector(
    (state: RootState) => state.standaloneInvoice.data
  );
  const cancelPaymentLoader = useSelector(
    (state: RootState) => state.blPayment.cancelPaymentLoader
  );

  const acceptPaymentLoader = useSelector(
    (state: RootState) => state.blPayment.acceptPaymentLoader
  );
  const rejectPaymentLoader = useSelector(
    (state: RootState) => state.blPayment.rejectPaymentLoader
  );
  const selectedRouter = useSelector(
    (state: RootState) => state.documentHistory.selectedRouter
  );
  const [showPaymentConfirm, setShowPaymentConfirm] = useState(false);
  /*  const [platformfee, setPlatformfee] = useState<number>(0); */
  const platformChargeConfigId = useSelector(
    (state: RootState) => state.blPayment.platformChargeConfigId
  );
  const [totAmt, setTotamt] = useState<any>("");

  const location = useLocation();
  const [selectedFileServerPath, setSelectedFileServerPath] = useState<
    Array<string | null>
  >([null, null]);
  const [loadingFileState, setLoadingFileState] = useState(0);
  const [attachedFileSize, setAttachedFileSize] = useState(0);

  const [paymentRefFocus, setpaymentRefFocus] = useState(false);
  const [rejectionMsg, setrejectionMsg] = useState<any>("");

  const [loading, setLoading] = useState<boolean>(false);

  const [showDuplicacy, setshowDuplicacy] = useState(false);

  const [duplicayID, setduplicayID] = useState<any>("");
  const currentDateTime = new Date().toISOString();
  const [showEditDuplicacy, setShowEditDuplicacy] = useState(false);
  const [showPaymentCancel, setPaymentCancel] = useState(false);
  const [editDuplicayMsg, seteditDuplicayMsg] = useState<any>("");
  const [methodName, setMethodName] = useState<any>("");

  const [newPaymentRef, setNewPaymentRef] = useState(false);
  const [duplicayMsg, setduplicayMsg] = useState<any>("");
  const amountDetails = useSelector(
    (state: RootState) => state.blPayment.amountDetails
  );

  const pymtConf = useSelector((data: RootState) => data?.blPayment.pymtConf)

  const rowColumns = useSelector(
    (state: RootState) => state.blDetailsTable.rowColumns
  );

  const { pmtid } = useParams();
  const mandatePmtId = AESDecrypt(pmtid)
  // Flag for when Attachment upload hard stop before CRR occurs
  const isAttachmentMandate = location.pathname.includes("unpaid") && mandatePmtId;

  const selecteData = useSelector(
    (state: RootState) => state.multiBlPayment.selectedRowsMultiPay
  );

  const selecteCredData = useSelector(
    (state: RootState) => state.creditBalance.selectedCreditData
  );

  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);

  const creditBalanceList = useSelector(
    (state: RootState) => state.creditBalanceBlPayment.data
  );

  const multiplePmtRef = useSelector(
    (state: RootState) => state.paymentBlPayment.multiplePmtRef
  );
  const pmtReftabledata = useSelector(
    (state: RootState) => state.paymentBlPayment.pmtReftabledata
  );
  const bankData = useSelector(
    (state: RootState) => state.blPayment.bankOptions
  );

  const pymtModeOptions = useSelector(
    (state: RootState) => state.blPayment.pymtModeOptions
  );

  const initialInvoiceDetailsPmtId = useSelector(
    (state: RootState) => state.paymentBlPayment.initialInvoiceDetailsPmtId
  );
  const tabledata = useSelector(
    (state: RootState) => state.documentHistory.data
  );
  const invoiceDashboardData = useSelector(
    (state: RootState) => state.dashboard.invoiceDashboardList
  );
  const paymentData = useSelector((state: RootState) => state.paymentBlPayment);
  const pmtRefNo = useSelector((state: RootState) => state.payment.pmtRefNo);

  const navigate = useNavigate();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const selectedLinerId = useSelector(
    (state: RootState) => state.navbarTop.linerId
  );

  const partnerCountry = profileDetails.partnerCountry;

  const storedUserCountry = JSON.parse(
    window.localStorage.getItem("@user-selected-datacountry") || "{}"
  );

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );

  const selectedAmount = useSelector(
    (state: RootState) => state.multiBlPayment.selectedAmountAll
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry =
    storedCountry ?? linerDataCountry ?? profileDetails.partnerCountry ?? null;

  const invoiceCountry =
    selectedAmount[0]?.collector_code !== undefined && selectedAmount.length > 0
      ? selectedAmount[0]?.collector_code.trim()
      : dataCountry;
  const [paymentCountry, setPaymentCountry] = useState(invoiceCountry);

  useEffect(() => {
    dispatch(
      getMultiCountryAllowance({
        country: invoiceCountry,
        linerId: selectedLinerId,
      })
    );
  }, [invoiceCountry]);
  const dispatch = useDispatch<AppDispatch>();
  const onBack = useBackNavigation();
  const validationFlag = useSelector(
    (state: RootState) => state.paymentBlPayment.validationFlag
  );
  const invoiceDetails = useSelector(
    (state: RootState) => state.paymentBlPayment.invoiceDetailsPmtId
  );
  const pmtNumberdata = useSelector(
    (state: RootState) => state.paymentBlPayment.pmtNumberdata
  );

  const [showPaymentRefInfo, setPaymentRefInfo] = useState(false);
  const [showVideoPopup, setShowVideoPopup] = useState(false)

  const cdnCountry = useCDNCountry();

  const pymtRefNo = useParams()?.blId;
  useEffect(() => {
    if (!disableFields) {
      dispatch(getPaymentBanks(1));
      dispatch(setEditPayment(false));
      dispatch(setPaymentStatus(""));
    }
    dispatch(resetPaymentLoaders());
  }, []);

  useEffect(() => {
    if (paymentAmount && linerId && selectedCcy && paymentCountry && rbac?.rbacDetails?.showPaymentTransaction == 'Y') {
      dispatch(getTransactionDetails({
        country: paymentCountry,
        ccy: selectedCcy,
        amount: paymentAmount,
        linerId: linerId
      }))
    }
  }, [paymentAmount, linerId, selectedCcy, paymentCountry]);

  const matchPth =
    matchPath(
      {
        ///bl/:blNum/payment/:pymtRefNo/:pmtMode
        path: "/bl/:blNum/payment/:pymtRefNo",
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: "instruction/bl/:blNum/payment/:pymtRefNo",
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: "payment/bl/:blNum/payment/:pymtRefNo",
        caseSensitive: true,
        end: true,
      },
      location.pathname
    );

  function extractValuesFromUrl(url: any) {
    const params = new URLSearchParams(url);
    const sortParam = params.get("sort");

    if (sortParam) {
      const arr: any = sortParam.split("%2C");
      return arr;
    } else {
      return [];
    }
  }

  const [filteredArrayData, setFilteredArrayData] = useState<any>([]);
  const disableFields = isAttachmentMandate ||
    profileDetails?.partnerType === "liner" ||
    (matchPth && profileDetails?.partnerType !== "liner") ||
    location.pathname.includes("blsliderpayment")
    ? true
    : false;
  const rbac = useSelector((state: RootState) => state.userProfile);
  const showMainLayout = useSelector(
    (state: RootState) => state.themeSettings.showMainLayout
  );
  const showPaymentProofAttachment = pymtConf?.paymentProofAttachment === "O" || pymtConf?.paymentProofAttachment === "M"

  useEffect(() => {
    invoiceDashboardData.result?.forEach((d: any, i: any) => {
      if (
        paymentData.pmtNumberdata?.map?.((v: any) => v.invNo).includes(d.invno)
      )
        filteredArrayData.push(d.pmtreference);
    });
    setFilteredArrayData(filteredArrayData);
  }, [invoiceDashboardData, paymentData]);

  useEffect(() => {
    if (paymentMode && linerId && paymentCountry) {
      dispatch(getPymtConfDetails({ paymentMode, linerId, paymentCountry }))
    }
  }, [paymentMode, linerId, paymentCountry]);

  useEffect(() => {
    if (
      isAttachmentMandate || (disableFields == false && showPaymentProofAttachment)
    ) {
      dispatch(getAppConfig("attachment.maxsize"))
        .unwrap()
        .then((response: any) => {
          if (response !== "") {
            setAttachedFileSize(+response.response.data);
          }
        });
    }
  }, [disableFields, pymtConf?.paymentProofAttachment]);

  useEffect(() => {
    if (modalCloseFocus.current) {
      setTimeout(() => {
        if (modalCloseFocus.current) modalCloseFocus.current.focus();
      }, 500);
      // modalCloseFocus.current.focus();
    }
  }, [showPaymentConfirm]);

  useEffect(() => {
    if (paymentRefFocus)
      if (modalCloseFocus.current) {
        setTimeout(() => {
          if (modalCloseFocus.current) modalCloseFocus.current.focus();
        }, 500);
        // modalCloseFocus.current.focus();
      }
  }, [paymentRefFocus]);

  useEffect(() => {
    if (paymentCountry !== null && paymentCountry != "" && selectedLinerId) {
      dispatch(
        getPymtModeOptions({
          partnerCountry: paymentCountry,
          selectedLinerId:
            profileDetails.partnerType === "liner"
              ? profileDetails?.partnerId
              : selectedLinerId,
        })
      )
        .unwrap()
        .then((data) => {
          if (!disableFields) {
            dispatch(updatePaymentMode(data[0].value));
          }
        });
    }
  }, [paymentCountry, selectedLinerId]);
  const [selectedTransactions, setSelectedTransactions] = useState<any[]>([]);

  const selectedBls = useSelector(
    (state: RootState) => state.multiBlPayment.selectedRowsMultiPay
  );
  const paycargoRef = useRef<any | null>(null);
  const modalCloseFocus = useRef<any>(null);
  const totalAmtVal = useRef<any>(null);
  const [env, setEnv] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [code, setCode] = useState("");
  const [options, setOptions] = useState({
    env: "test",
    code: "odex", // Provied by PayCargo
    brand: "odex", // Provied By PayCargo
    originURL: window.location.origin, // URL to whitelist
  });

  useEffect(() => {
    dispatch(getAppConfig("env"))
      .unwrap()
      .then((response: any) => {
        if (response !== "") {
          setEnv(response.response.data.toString().split("_")[0]);
          setVendorId(response.response.data.toString().split("_")[1]);
          setCode(response.response.data.toString().split("_")[2]);
        }
      });
  }, []);
  useEffect(() => {
    setOptions({
      env: env, // PROD , TEST, DEV
      code: code || "odex", // Provied by PayCargo
      brand: code || "odex", // Provied By PayCargo
      originURL: window.location.origin, // URL to whitelist
    });
  }, [env]);

  useEffect(() => {
    const newArray =
      amountDetails > 0
        ? selectedBls.map((item: any) => ({
          type: "INVOICE",
          number: item.invno,
          total: item.invamt,
          departureDate: item.invdt,
          arrivalDate: item.invdt,
          paymentDueDate: item.invdt,
          customerRefNumber: item.shipmentno || item.blno,
          parent: item.blno,
          hasArrived: "N",
          direction: item.eximindicator === "Export" ? "Outbound" : "Inbound",
          vendorId: vendorId, //278606,281573
        }))
        : [];
    const standArray = standaloneInvoiceList.map((item: any) => ({
      type: "INVOICE",
      number: item.invoiceNo,
      total: item.invoiceAmount,
      departureDate: item.invDt,
      arrivalDate: item.invDt,
      paymentDueDate: item.invDt,
      customerRefNumber: item.shipmentNumber || item.blNo,
      parent: item.blNo,
      hasArrived: "N",
      direction: item.eximindicator !== "C" ? "Outbound" : "Inbound",
      vendorId: vendorId,
    }));
    setTimeout(() => {
      setSelectedTransactions(
        location.pathname.includes("addInvoice") ? standArray : newArray
      );
    }, 100);
  }, [
    selectedBls,
    paymentMode,
    vendorId,
    amountDetails,
    standaloneInvoiceList,
  ]);

  let paycargoInvoiceList = [{}];

  const [integrationResponse, setIntegrationResponse] = useState("");

  useEffect(() => {
    if (paymentMode == 6) {
      const hideLayout = () => {
        dispatch(toggleMainLayout(false));
        const errorMessage = paycargoRef?.current?.shadowRoot
          ?.querySelectorAll("div")[0]
          ?.getElementsByClassName("hydrated")?.[0]
          ?.shadowRoot?.querySelectorAll("div")?.[0]
          ?.getElementsByClassName("toast error")?.[0]?.innerText;
        if (errorMessage !== undefined) dispatch(toggleMainLayout(true));
      };
      const listener = (event: any) => {
        if (event.detail.success) {
          paycargoInvoiceList = event.detail.data.success.approve.map(
            (item: any) => ({ invNo: item.number, invAmt: item.totalAmount })
          );
          setIntegrationResponse(JSON.stringify(event));
          handleSubmit();
        }
        setTimeout(() => dispatch(toggleMainLayout(true)), 150);
      };

      paycargoRef?.current?.addEventListener("close", listener);
      paycargoRef?.current?.addEventListener("paymentResponse", listener);
      paycargoRef?.current?.addEventListener("click", hideLayout);

      return () => {
        paycargoRef?.current?.removeEventListener("close", listener);
        paycargoRef?.current?.removeEventListener("paymentResponse", listener);
        paycargoRef?.current?.removeEventListener("click", hideLayout);
      };
    }
  }, [paycargoRef, paymentMode]);

  // useEffect(() => {
  //   debugger;
  //   if (paycargoInvoiceList.length > 0 && paymentMode == 6) {
  //     handleSubmit();
  //   }
  // }, [paycargoInvoiceList]);

  const _onCloseForm = () => {
    onBack();
    if (location.pathname.includes("unpaid")) {
      navigate(location.pathname.replace(/\/unpaid.*/, "/unpaid"));
    }
    if (location.pathname.includes("instructionpayment")) {
      dispatch(toggleDocumentHistoryColumnShort(false));

      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: "col-lg-2",
          documentMainViewClass: "d-none",
          documentHistoryClass: "col-lg-12",
          outletClass: "d-none",
        })
      );
    } else if (location.pathname.includes("204")) {
      dispatch(toggleDocumentHistoryColumnShort(false));

      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: "col-lg-2",
          documentMainViewClass: "d-none",
          documentHistoryClass: "col-lg-12",
          outletClass: "d-none",
        })
      );

      setTimeout(() => {
        //  navigate(-1);
      }, 500);
    } else if (location.pathname.includes("blsliderpayment")) {
      setTimeout(() => {
        //  navigate(`/payment${window.location.search || ''}`);
      }, 500);
    } else if (location.pathname.includes("payment/new")) {
      setTimeout(() => {
        //  navigate(-1);
      });
    } else if (location.pathname == "/payment/addInvoice/invoicePayment") {
      // if (window.location.search) {
      //   const paramVal = extractValuesFromUrl(window.location.search);
      //   const splitedval = paramVal[0].split(',');
      //   setTimeout(() => {
      //     //  debugger;
      //     dispatch(
      //       updateSortStateDataTable({
      //         key: splitedval[2],
      //         value: splitedval[3],
      //       })
      //     );
      //   }, 100);
      // }
      navigate("/payment/addInvoice");
    } else if (
      location.pathname.includes("payment") &&
      !location.pathname.includes("unpaid")
    ) {
      setTimeout(() => {
        if (selectedRouter === "payment") {
          // navigate(
          //   `/payment/${encodeURIComponent(blNum || '')}${
          //     window.location.search || ''
          //   }`
          // );

          if (window.location.search) {
            const paramVal = extractValuesFromUrl(window.location.search);
            const splitedval = paramVal[0].split(",");
            setTimeout(() => {
              //  debugger;
              dispatch(
                updateSortStateDataTable({
                  key: splitedval[0],
                  value: splitedval[1],
                })
              );
            }, 100);
          }
        } else if (selectedRouter == "instruction") {
          // navigate(
          //   `/instruction/${encodeURIComponent(blNum || '')}${
          //     window.location.search || ''
          //   }`
          // );

          if (window.location.search) {
            const paramVal = extractValuesFromUrl(window.location.search);
            const splitedval = paramVal[0].split(",");
            setTimeout(() => {
              dispatch(
                updateSortStateDataTable({
                  key: splitedval[0],
                  value: splitedval[1],
                })
              );
            }, 100);
          }
        } else {
          if (window.location.search) {
            const paramVal = extractValuesFromUrl(window.location.search);
            const splitedval = paramVal[0].split(",");
            setTimeout(() => {
              //  debugger;
              dispatch(
                updateSortStateDataTable({
                  key: splitedval[0],
                  value: splitedval[1],
                })
              );
            }, 100);
          }
        }
      }, 500);
    } else {
      // navigate(-1);
      dispatch(enableBlPayment(false));
    }
  };

  const onChangePaymentMode = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(updatePaymentMode(e.target.value));
  };

  const selectedDocHistory: any = useSelector(
    (state: RootState) => state.documentHistory.data
  );

  const usdPayableAmount =
    window.location.pathname === "/multi-payment"
      ? selectedBls
        .filter((v: any) => v.currency === "USD")
        .map((i: any) => Number(i.amount))
        .reduce((partialSum: any, a: any) => partialSum + a, 0)
      : selectedDocHistory
        .filter((v: any) => v.currency === "USD")
        .map((i: any) => Number(i.amount))
        .reduce((partialSum: any, a: any) => partialSum + a, 0);

  const zarPayableAmount =
    window.location.pathname === "/multi-payment"
      ? selectedBls
        .filter((v: any) => v.currency === "ZAR")
        .map((i: any) => Number(i.amount))
        .reduce((partialSum: any, a: any) => partialSum + a, 0)
      : selectedDocHistory
        .filter((v: any) => v.currency === "ZAR")
        .map((i: any) => Number(i.amount))
        .reduce((partialSum: any, a: any) => partialSum + a, 0);

  const [selected, setSelected] = useState(bankData[0]?.value);
  const [bnkName, setBnkName] = useState(bankData[0]?.bankName || "");
  const [acName, setAcName] = useState(bankData[0]?.accountNo || "");
  const [inputValue, setInputValue] = useState("");
  const [inputValueTemp, setInputValueTemp] = useState<any>("");

  const [radioValue, setRadioValue] = useState("auto");
  const [pmtId, setPmtId] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState<any>();

  useEffect(() => {
    if (
      !disableFields &&
      radioValue === "auto" &&
      inputValueTemp?.trim() === ""
    ) {
      dispatch(
        getPymtRefNo({
          partnerCountry,
        })
      );
    }
  }, [radioValue, partnerCountry]);

  useEffect(() => {
    if (radioValue === "auto") {
      if (pmtRefNo) setInputValue(pmtRefNo);
      setInputValueTemp(pymtRefNo);
    }
  }, [pmtRefNo, radioValue]);

  useEffect(() => {
    if (paymentMode == 6) { setShowVideoPopup(true) }
    else { setShowVideoPopup(false) }
    if ((paymentMode == 9 && !disableFields) || (paymentMode == 1 && dataCountry == "PK")) {
      setRadioValue('generate')
      setInputValueTemp("inputValue");
      setInputValue("");

    } else if (paymentMode == 1 && !disableFields && dataCountry === 'SG') {
      setRadioValue('auto')
      setInputValue("");
    } else if (paymentMode == 1 && !disableFields) {
      setRadioValue("auto");
    }
  }, [paymentMode, disableFields, paymentCountry]);

  useEffect(() => {
    const newval = pmtReftabledata.reduce(
      (sum: any, obj: { invAmt: string }) =>
        sum +
        (typeof obj.invAmt === "string" ? parseFloat(obj.invAmt) : obj.invAmt),
      0
    );
    totalAmtVal.current = newval;
    setselectedTotal(newval);
  }, [pmtReftabledata]);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelected(e.target.value);
    setBnkName(
      e.target.value
        ? bankData.find((v: any) => v.value == e.target.value)?.bankName || ""
        : ""
    );
    setAcName(
      e.target.value
        ? bankData.find((v: any) => v.value == e.target.value)?.accountNo || ""
        : ""
    );
  };

  const pymtMode = useSelector((state: RootState) => state.blPayment.pymtMode);
  const paymentLoaderCheck = useSelector(
    (state: RootState) => state.blPayment.paymentSubmitLoader
  );
  const pymtNo = useSelector((state: RootState) => state.blPayment.pymtNo);
  const editPayment: any = useSelector(
    (state: RootState) => state.blPayment.editPayment
  );
  const bnkNm = useSelector((state: RootState) => state.blPayment.bnkNm);
  const accNo = useSelector((state: RootState) => state.blPayment.accNo);
  const pymtAmt = useSelector((state: RootState) => state.blPayment.pymtAmt);
  const pymtCurr = useSelector((state: RootState) => state.blPayment.pymtCurr);
  const platformfee = useSelector(
    (state: RootState) => state.blPayment.platformFee
  );
  const totalInvAmt = useSelector(
    (state: RootState) => state.blPayment.totalInvAmt
  );
  const [paymentProofFileName, setPaymentProofFileName] = useState("");
  const payorAccountId = useSelector(
    (state: RootState) => state.blPayment.payorAccountId
  );

  const newStandaloneInvoiceList: any = standaloneInvoiceList.map(
    (invoice: StandaloneInvoiceDetails) => {
      return {
        amount: invoice.invoiceAmount,
        id: invoice.invoiceNo,
        invID: invoice.invoiceId,
        invcurr: invoice.invoiceCurrency,
        ccy: invoice.invoiceCurrency,
      };
    }
  );
  const newUnpaidData: any = unpaidData
    .map((item) => ({
      amount: item.amount,
      id: item.invno,
      invID: item.invid,
      invcurr: item.ccy,
    }))
    .filter(
      (item) =>
        item.invcurr ===
        (unpaidData.find((item) => item.ccy != "USD")?.ccy ?? "USD")
    );

  const filteredselectedAmount = matchPath(
    "/payment/addInvoice/invoicePayment",
    location.pathname
  )
    ? newStandaloneInvoiceList
    : location.pathname.includes("unpaid")
      ? newUnpaidData
      : selectedAmount.filter(
        (item: { invcurr: any; id: any; localamount: any }) =>
          item.invcurr === selectedAmount[0]?.invcurr &&
          (item?.id?.substring(0, 2) == "CD") ==
          (selectedAmount[0]?.id.substring(0, 2) == "CD") &&
          isNaN(item.localamount) == isNaN(selectedAmount[0].localamount)
      );


  const radioVal = useSelector(
    (state: RootState) => state.blPayment.radioValue
  );
  const pmtStatus = useSelector(
    (state: RootState) => state.blPayment.pmtStatus
  );
  const localAmount = useSelector(
    (state: RootState) => state.blPayment.localamount
  );

  const localccy = useSelector((state: RootState) => state.blPayment.localccy);

  const linerRemarks = useSelector(
    (state: RootState) => state.blPayment.linerRemarks
  );

  const pymtId = useSelector((state: RootState) => state.blPayment.pmtId);
  const fileName = useSelector((state: RootState) => state.blPayment.fileName);

  const resetValues = () => {
    setAdminRefNumber('');
    setBankRefNoLiner('');
  }

  useEffect(() => {
    if (
      location.pathname !== "/payment/new" &&
      !location.pathname.includes("unpaid/payment")
    ) {
      setBnkName(bnkNm);
      setAcName(accNo);
    }
  }, [bnkNm, accNo]);

  useEffect(() => {
    setPaymentCountry(invoiceCountry)
  }, [invoiceCountry])

  useEffect(() => {
    //if (location.pathname.split('/')[1] === 'blsliderpayment'){

    if (
      isAttachmentMandate ||
      location.pathname !== "/payment/new" &&
      !location.pathname.includes("unpaid/payment")
    ) {
      dispatch(
        getPymtDetailsByPymtNo({
          pymtId: isAttachmentMandate ? mandatePmtId :
            location.pathname.split("/")[1] === "blsliderpayment"
              ? location.pathname.split("/")[2]
              : pymtRefNo,
        })
      ).unwrap().then((data) => {
        setPaymentDetails(data);
        setPaymentCountry(data?.countryCode?.trim() ?? dataCountry)
      });
    }
    resetValues();
    // //} else if(location.pathname.includes('payment')){
    //   dispatch(getPymtDetailsByPymtNo({
    //     pymtNo: location.pathname.split('/')[4]
    //   }));
    // }
  }, [pymtNo, location.pathname]);
  useEffect(() => {
    // settlement API for tolerence requirement (not in use right now)
    if (selectedLinerId && false) {
      dispatch(
        getCreditBalance({
          selectedLinerId:
            profileDetails.partnerType === "liner"
              ? profileDetails?.partnerId
              : selectedLinerId,
        })
      );
    }
    dispatch(paymentLoader(false));
  }, [selectedLinerId]);

  useEffect(() => {
    if (location.pathname.includes("new")) {
      setPmtId(0)
    }
  }, [location.pathname])

  useEffect(() => {
    if (disableFields) {
      pymtMode !== ""
        ? dispatch(updatePaymentMode(pymtMode))
        : dispatch(updatePaymentMode(pymtModeOptions[0].value));
      pymtNo !== "" ? setInputValue(pymtNo) : setInputValue("");
      pymtAmt !== "" ? setPaymentAmount(pymtAmt) : setPaymentAmount("");
      pymtCurr !== "" ? setSelectedCcy(pymtCurr) : setSelectedCcy("USD");
      pymtId ? setPmtId(pymtId) : setPmtId(0);
      setRadioValue(radioVal);
      if (payorAccountId) {
        setSelected(payorAccountId);
      } else {
        setSelected(bankData[0]?.value);
        setBnkName(bankData[0]?.bankName || "");
        setAcName(bankData[0]?.accountNo || "");
      }
    } else {
      setSelected(bankData[0]?.value);
      setBnkName(bankData[0]?.bankName || "");
      setAcName(bankData[0]?.accountNo || "");

      //setRadioValue("auto");
    }
  }, [pymtNo, bankData, pymtCurr]);

  const handleDuplicacy = () => {
    var newSelectedData: any = [];

    if (lastEndpoint == "instructionpayment") {
      const desiredInstructions = ["780", "102", "103", "381"];

      newSelectedData = tabledata
        .filter((item: any) => desiredInstructions.includes(item.instructionId))
        .map((item: any) => ({
          invId: item.docId,
          invNo: item.documentNumber,
        }));
    } else {
      newSelectedData = filteredselectedAmount?.map((item: any) => ({
        invId: item.invID,
        invNo: item.id,
        invAmt: item.amount,
      }));
    }
    const state = {
      paymentMode: paymentMode,
      inputValue: inputValue,
    };

    dispatch(
      addInvoiceToExistingPayment({
        invoices: newSelectedData,
        pmtId: duplicayID,
        paymentFundings: [],
        paymentMode: paymentMode,
        paymentRefNo: /*  paymentMode == 2 ? '' : */ inputValue,
        currency: filteredselectedAmount?.[0]?.invcurr || "USD",
        payorAccountId: pymtMode == 1 ? selected : "",
        linerId: linerId,
        pmtAmount: amountDetails,
        bankRefNo: radioValue != "auto" ? inputValue : "",
        pmtProofAtt: "",
        paymentProofFileName: paymentProofFileName,
        serverFilePath: selectedFileServerPath[0],
        tempFileName: selectedFileServerPath[1],
      })
    );

    setTimeout(() => {
      navigate("/payment");
      dispatch(updateSelectedRowsInvoice([]));
      dispatch(updateSelectedAmount([]));
    }, 500);
  };

  const [showConfirmMsg, setShowConfirmMsg] = useState(false);
  const checkValidationAndUpdate = async () => {
    var filteredArray: any = [];

    const duplicateFlag = await dispatch(
      checkDuplicateReferenceNoByPymtId({
        inputValue,
        selectedCcy: pymtCurr,
        selectedPymtId: pymtId,
        pymtAmt: formatNumber(paymentAmount),
      })
    );

    if (duplicateFlag?.payload == true) {
      if (editPayment) {
        if (pmtReftabledata.length == 0) {
          setShowConfirmMsg(true);
        } else if (pmtNumberdata > pmtReftabledata) {
          setShowConfirmMsg(true);
        } else {
          handleUpdate();
        }
      }
    } else {
          setLoading(false)
      if (duplicateFlag?.payload != undefined) {
        setShowEditDuplicacy(true);
        setpaymentRefFocus(false);
        //setduplicayID(duplicateFlag.payload);
        seteditDuplicayMsg(Object.values(duplicateFlag?.payload)[0]);
        if (
          Object.values(duplicateFlag?.payload)[0] ==
          "Payment Reference No. already exists."
        ) {
          setMethodName("handleUpdate");
        }
      }
    }
  };

  const handleUpdate = () => {
    var filteredArray: any = [];
    invoiceDashboardData.result?.forEach((d: any, i: any) => {
      if (
        paymentData.pmtNumberdata?.map?.((v: any) => v.invNo).includes(d.invno)
      )
        filteredArray.push(d.pmtReference);
    });

    dispatch(
      editBankPymtRefNo({
        invoices: pmtReftabledata.map((item: any) => ({
          invId: item.invid ? item.invid : item.invId,
          invNo: item.invNo,
          invAmt: item.invAmt,
        })),

        pmtId: pmtId,
        paymentFundings: [],
        paymentMode: paymentMode,
        paymentRefNo: /*  paymentMode == 2 ? '' : */ inputValue,
        currency: selectedCcy,
        payorAccountId: pymtMode == 1 ? selected : "",
        linerId: linerId,
        pmtAmount: pmtReftabledata.reduce(
          (sum: any, obj: { invAmt: string }) =>
            sum +
            (typeof obj.invAmt === "string"
              ? parseFloat(obj.invAmt)
              : obj.invAmt),
          0
        ),
        bankRefNo: radioValue != "auto" ? inputValue : "",
        pmtProofAtt: "",
        paymentProofFileName: paymentProofFileName,
        refType: paymentMode == 9 ? 3 : radioValue === "auto" ? 1 : 2,
        serverFilePath: selectedFileServerPath[0],
        tempFileName: selectedFileServerPath[1],
      })
    )
      .unwrap()
      .then((response: any) => {
        if (response !== "") {
          setTimeout(() => {
            if (window.location.pathname.includes("blsliderpayment")) {
              navigate("/payment");
              dispatch(updateSelectedRowsInvoice([]));
              dispatch(updateSelectedAmount([]));
            } else {
              navigate("/home");
            }
          }, 1500);
        }
      });
    // setTimeout(() => {
    //   navigate(-1);
    // }, 500);
  };
  const initialOptions = {
    clientId:
      "AV1xxFp72k264HFbt5pwbKcj31pP3oCX-8qJZYuOvRL4wYhum6JdpXZRlfJKeHcuFJ2RwuszDuCcePWi",
    currency: "USD",
    intent: "capture",
  };
  const handleSubmitWithDuplicate = async () => {
    var newSelectedData = [];

    if (lastEndpoint == "instructionpayment") {
      const desiredInstructions = ["780", "102", "103", "381"];

      newSelectedData = tabledata
        .filter((item: any) => desiredInstructions.includes(item.instructionId))
        .map((item: any) => ({
          invId: item.docId,
          invNo: item.documentNumber,
        }));
    } else {
      newSelectedData = filteredselectedAmount?.map((item: any) => ({
        invId: item.invID,
        invNo: item.id,
      }));
    }

    const newpaymentFund = selecteCredData?.map(
      (item: { paymentFundingId: any }) => ({
        paymentFundingId: item.paymentFundingId,
      })
    );
    const tooltipContent = filteredselectedAmount.map(
      (item: any) => `● ${item.id} `
    );

    const newPayload = {
      invoices: newSelectedData,
      paymentFundings: paymentMode == 1 ? [] : newpaymentFund,
      paymentMode: paymentMode,
      paymentRefNo: pmtRefNo,
      currency: filteredselectedAmount?.[0]?.invcurr || "USD",
      payorAccountId: pymtMode == 1 ? selected : "",
      linerId: linerId,
      pmtAmount: amountDetails,
      bankRefNo: "", //pmtRefNo,
      pmtProofAtt: "",
      paymentProofFileName: paymentProofFileName,
      serverFilePath: selectedFileServerPath[0],
      tempFileName: selectedFileServerPath[1],
    };

    dispatch(submitPaymentBanks(newPayload));
  };
  const handleSubmit = async () => {
    // navigate(-1);
    /// dispatch(updateSelectedBlListMultiPayTable({}));
    // dispatch(updateSelectedAmount([]));
    // dispatch(updateSelectedRowsInvoice([]));

    var newSelectedData = [];

    if (matchPath("/payment/addInvoice/invoicePayment", location.pathname)) {
      newSelectedData = standaloneInvoiceList.map(
        (invoice: StandaloneInvoiceDetails) => ({
          invId: invoice.invoiceId,
          invNo: invoice.invoiceNo,
        })
      );
    } else if (location.pathname.includes("unpaid")) {
      newSelectedData = newUnpaidData.map((invoice: any) => ({
        invId: invoice.invID,
        invNo: invoice.id,
      }));
    } else if (lastEndpoint == "instructionpayment") {
      const desiredInstructions = ["780", "102", "103", "381"];

      newSelectedData = tabledata
        .filter((item: any) => desiredInstructions.includes(item.instructionId))
        .map((item: any) => ({
          invId: item.docId,
          invNo: item.documentNumber,
        }));
    } else {
      newSelectedData = filteredselectedAmount?.map((item: any) => ({
        invId: item.invID,
        invNo: item.id,
      }));
    }
    const newpaymentFund = selecteCredData?.map(
      (item: {
        paymentFundingId: any;
        //unAllocatedAmount: any;
        //allocatedAmount: any;
      }) => ({
        paymentFundingId: item.paymentFundingId,
        /*    allocatedAmt:item.allocatedAmount, */
        //allocatedAmt: String(parseInt(item.allocatedAmount) + parseInt(amountDetails)),
      })
    );
    const tooltipContent = filteredselectedAmount.map(
      (item: any) => `● ${item.id} `
    );
    let testdata = newSelectedData.filter((v: any) =>
      paycargoInvoiceList.map((item: any) => item.invNo).includes(v.invNo)
    );

    const newPayload = {
      invoices:
        paymentMode == 6
          ? newSelectedData.filter((v: any) =>
            paycargoInvoiceList.some((item: any) => v.invNo == item.invNo)
          )
          : newSelectedData,
      paymentFundings: paymentMode == 1 ? [] : newpaymentFund,
      paymentMode: paymentMode,
      paymentRefNo: paymentMode == 2 ? "" : inputValue,
      currency: filteredselectedAmount?.[0]?.invcurr || "USD",
      payorAccountId: pymtMode == 1 ? selected : "",
      linerId: linerId,
      pmtAmount:
        paymentMode == 6
          ? paycargoInvoiceList
            .map((i: any) => Number(i.invAmt))
            .reduce((partialSum: any, a: any) => partialSum + a, 0)
          : amountDetails,
      localAmount: multiCurrencyAllow
        ? selectedAmount
          .map((item: any) => item?.localamount)
          .reduce((partialSum: any, a: any) => partialSum + a, 0)
        : "",
      localCcy: multiCurrencyAllow ? selectedAmount[0]?.localccy : "",
      bankRefNo: "",
      pmtProofAtt: "",
      paymentProofFileName: paymentProofFileName,
      serverFilePath: selectedFileServerPath[0],
      tempFileName: selectedFileServerPath[1],
      refType: paymentMode == 9 ? 3 : radioValue === "auto" ? 1 : 2,
      integrationResponse: paymentMode == 6 ? integrationResponse : "",
    };

    {
      if (
        window.location.pathname === "/payment/new" ||
        location.pathname.includes("unpaid") ||
        location.pathname.includes("addInvoice")
      ) {
        const duplicateFlag = await dispatch(
          checkDuplicateReferenceNo({
            inputValue,
            selectedCcy: filteredselectedAmount?.[0]?.invcurr || "USD",
          })
        );

        if (duplicateFlag.payload == true) {
          tooltipContent?.length > 0
            ? paymentMode == 6
              ? dispatch(submitAutoPayment(newPayload))
              : dispatch(submitPaymentBanks(newPayload))
            : dispatch(
              submitPaymentAddFund({
                pmtReference: inputValue,
                pmtMode: paymentMode,
                pmtAmount: amountDetails,
                currency: selectedCcy,
                pmtProofAtt: "",
                payorBankName: bnkName,
                payorAccountId: selected,
                pmtStatus: "2",
                pmtType: "ADD FUNDS ",
                linerId: linerId,
                // selectedLinerId: selectedLinerId,
              })
            )
              .unwrap()
              .then((response: any) => {
                if (response.data.statusCode === 400) {
                  toast.dark(response.data.message);
                } else {
                  toast.dark(response.data.message);
                }
              });
          if (location.pathname.includes("unpaid")) {
            setTimeout(() => {
              navigate(location.pathname.replace("unpaid/payment", "unpaid"));
            }, 2000);
          } else {
            navigate("/payment");
          }
          setTimeout(() => {
            dispatch(updateDashbaordRefresh(true));
            dispatch(updateSelectedRowsInvoice([]));
            dispatch(updateSelectedAmount([]));
          }, 1500);
        } else {
          if (duplicateFlag.payload != undefined) {
            setshowDuplicacy(true);
            setpaymentRefFocus(false);
            //setduplicayID(duplicateFlag.payload);
            setduplicayID(Object.keys(duplicateFlag?.payload)[0]);
            setduplicayMsg(Object.values(duplicateFlag?.payload)[0]);
          }
        }
      } else {
        dispatch(submitPaymentBanks(newPayload));
        if (location.pathname.includes("unpaid")) {
          setTimeout(() => {
            navigate(location.pathname.replace("unpaid/payment", "unpaid"));
          }, 2000);
        } else {
          navigate("/payment");
          dispatch(updateDashbaordRefresh(true));
          dispatch(updateSelectedRowsInvoice([]));
          dispatch(updateSelectedAmount([]));
        }
      }
    }
    {
      if (paymentMode == 6) {
        let failedInvData = newSelectedData.filter((v: any) =>
          paycargoInvoiceList.some((item: any) => v.invNo != item.invNo)
        );

        // if (failedInvData.length > 0) {
        //   toast.dark(
        //     `${JSON.stringify(
        //       failedInvData.map((v: any) => v.invNo)
        //     )} payment has not been processed.`
        //   );
        // }
      }
    }

    // if(paymentMode == '1' ){

    //   const newSelectedData = selecteData?.map(item => ({
    //     invId: item.id,
    //     invNo: item.number
    //   }));

    //   const match = acName.match(/\d+/);
    //   const extractedNumber = match[0];

    //   const payload = {
    //     invoiceData: newSelectedData,
    //     paymentMode: paymentMode,
    //     bankName: bnkName,
    //     accNo: extractedNumber,
    //     payorAccountId: "1",
    //     paymentRefNo: "PUS1234567",
    //     pmtProofAttachment: "docPath of Upload",
    //     paymentAmt: amountDetails,
    //     currency: "USD"
    //   }

    //   const newpaymentFund = selecteCredData?.map((item: { paymentFundingId: any; allocatedAmount: any; pmtAmount: any; }) => ({
    //     paymentFundingId : item.paymentFundingId,
    //     allocatedAmt:item.allocatedAmount,
    //     pmtAmount:item.pmtAmount
    //   }));

    //   const newPayload = {
    //     invoices: newSelectedData,
    //     paymentFundings:paymentMode == '1' ? [] : newpaymentFund,
    //     paymentMode: paymentMode,
    //     paymentRefNo: "doodo",
    //     currency: "USD"
    //   };

    //   dispatch(submitPaymentBanks(payload));
    // }

    // if(paymentMode == '2'){

    //   const newSelectedData = selecteData?.map(item => ({
    //     invId:item.id,
    //     InvNo: item.number,
    //     InvCurrency: "USD"
    //   }));

    //   const newpaymentFund = selecteData?.map(item => ({
    //     paymentFundingId : item.paymentFundingId,
    //     bankName : 23,
    //     paymentAmt : item.paymentAmt,
    //     paymentCurrancy : "USD"
    //   }));

    //   const payload  = {
    //     invoiceData: newSelectedData,
    //     paymentFund: newpaymentFund,
    //     paymentMode: paymentMode,
    //     paymentRefNo: "",
    //     paymentAmt: amountDetails,
    //     currency: "USD"
    //   }
    //   dispatch(submitPaymentBanks(payload));
    // }
    /*  setTimeout(() => {
      navigate('/payment');
    }, 500); */
  };

  const [bankRefNoLiner, setBankRefNoLiner] = useState("");
  const [adminRefNumber, setAdminRefNumber] = useState("");
  const [selectedTotal, setselectedTotal] = useState("");

  const selectedCreditAmount = selecteCredData
    .map((i: any) => Number(i.unAllocatedAmount))
    .reduce((partialSum: any, a: any) => partialSum + a, 0);
  const totalCreditAmount = creditBalanceList
    ?.map((i: any) => Number(i.unAllocatedAmount))
    .reduce((partialSum: any, a: any) => partialSum + a, 0);

  useEffect(() => {
    if (
      selectedCreditAmount > 0 &&
      amountDetails > 0 &&
      selectedCreditAmount > amountDetails
    ) {
      // toast.error('Selected Invoice amount is greater than the Credit Balance');
    }
  }, [selecteCredData, amountDetails]);

  // const formatNumber = (value: string): string => {
  //   const parsedValue = parseFloat(value);
  //   if (isNaN(parsedValue)) return value; // Return the original value if it's not a valid number

  //   const roundedValue = parseFloat(parsedValue.toFixed(2)); // Round to two decimal places
  //   const [integerPart, decimalPart] = roundedValue.toString().split('.');
  //   const formattedInteger = parseInt(integerPart, 10).toLocaleString();
  //   const formattedDecimal = decimalPart ? `.${decimalPart}` : '';
  //   return `${formattedInteger}${formattedDecimal}`;
  // };

  const formatNumber = useAmountFormatter(selectedCcy);
  //const [editPayment, setEditPayment] = useState(false);

  const pmtNumberdataCount = useSelector(
    (state: RootState) => state.paymentBlPayment.pmtNumberdata
  );

  const transactionDetailsCols: IDataTableColumn[] = [
    {
      accessor: 'transaction_date',
      Header: "Transaction Date",
      Cell: (rowData: any) => {
        const { transaction_date } = rowData.row.original;
        const formattedDate = new Date(transaction_date).toLocaleString()
        return (
          <span
            data-toggle='tooltip'
            title={formattedDate}
          >{formattedDate}</span>
        );
      },
      minWidth: 150,
      width: 150,
      maxWidth: 250,
    },
    {
      accessor: 'payment_ref_no',
      Header: "Payment Ref No",

      Cell: (rowData: any) => {
        const { payment_ref_no } = rowData.row.original;
        return (
          <span
            data-toggle='tooltip'
            title={payment_ref_no}
          >{payment_ref_no}</span>
        );
      },
      minWidth: 130,
      width: 130,
      maxWidth: 150,
    },
    {
      accessor: 'bank_ref_no',
      Header: "Bank Ref No",
      Cell: (rowData: any) => {
        const { bank_ref_no } = rowData.row.original;
        const copyText = () => {
          navigator.clipboard.writeText(bank_ref_no);
          toast('Copied to clipboard');
          setBankRefNoLiner(bank_ref_no);
        }
        return (
          <span data-toggle='tooltip' title={bank_ref_no} onClick={copyText} className="mx-2 link-primary " style={{ cursor: 'pointer', fontWeight: 550 }}>{bank_ref_no}</span>
        );
      },
      minWidth: 150,
      width: 150,
      maxWidth: 170,
    },
    {
      accessor: 'amt',
      Header: "Amount",
      Cell: (rowData: any) => {
        const { amt } = rowData.row.original;
        return (
          <span
            data-toggle='tooltip'
            title={amt}
          >{amt}</span>
        );
      },
      minWidth: 90,
      width: 90,
      maxWidth: 110,
    },

    {
      accessor: 'ccy',
      Header: "CCY",
      Cell: (rowData: any) => {
        const { ccy } = rowData.row.original;
        return (
          <span
            data-toggle='tooltip'
            title={ccy}
          >{ccy}</span>
        );
      },
      minWidth: 55,
      width: 55,
      maxWidth: 55,
    },
    {
      accessor: 'payorName',
      Header: "Payor Name",
      Cell: (rowData: any) => {
        const { payorName } = rowData.row.original;
        return (
          <span
            data-toggle='tooltip'
            title={payorName}
          >{payorName}</span>
        );
      },
      minWidth: 120,
      width: 120,
      maxWidth: 170,
    },

    {
      accessor: 'remarks',
      Header: "Remarks",
      Cell: (rowData: any) => {
        const { remarks } = rowData.row.original;
        return (
          <span
            data-toggle='tooltip'
            title={remarks}
          >{remarks}</span>
        );
      },
      minWidth: 350,
      width: 350,
      maxWidth: 350,
    },
  ];

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];

      if (file.size > attachedFileSize) {
        toast.error(
          `File size is greater than ${Math.round(
            attachedFileSize / 1000000
          )}MB. Please upload file below ${Math.round(
            attachedFileSize / 1000000
          )}MB`
        );
        e.target.value = "";
        return;
      }
      if(file.name.length > 60){
        toast.error("File name should not exceed 60 characters");
        e.target.value = ""
        return
      }

      let fileExt = file.name.slice(-4);

      if (fileExt.toLowerCase() != ".pdf") {
        toast.error("Please upload Only PDF File");
        e.target.value = "";
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      try {
        setPaymentProofFileName(file.name);
        setSelectedFileServerPath([null, null]);
        setLoadingFileState(0);
        const resp = axiosInstance
          .post("/api/edo-rest-v1/v1/view/file", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress(progressEvent) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
              );
              setLoadingFileState(percentCompleted);
            },
          })
          .then((response) => {
            setLoadingFileState(100);
            if (!response?.data?.path || !response?.data?.tempFileName)
              throw new Error("upload error!");
            setSelectedFileServerPath([
              response.data.path,
              response.data.tempFileName,
            ]);
          })
          .catch(() => {
            setLoadingFileState(-1);
            setPaymentProofFileName("");
            e.target.value = "";
          });
      } catch (err) {
        console.error("File Upload Error: ", err);
        e.target.value = "";
        setLoadingFileState(-1);
        setPaymentProofFileName("");
      }
    }
    // const buffer = await file.arrayBuffer();
    // const bytes = new Uint8Array(buffer);
    // setByteArr(bytes.toString());
  };

  // const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  // useEffect(() => {
  //   if (+selectedTotal < 0) {
  //     setDisableSubmitButton(true);
  //   } else {
  //     setDisableSubmitButton(false);
  //   }
  // }, [selectedTotal]);

  const handleInputBlur = (e: any) => {
    if (inputValue != "" && paymentMode == 9) {
      setInputValue(
        // e.target.value.padStart(6,'0') /* .replace(/ /g, '') */
        e.target.value
          .replace(/^0+/, "")
          .replace(/[^0-9]/g, "")
          .replace(/\s+/g, " ")
          .trim()
      );
    } else {
      setInputValue(
        // e.target.value.padStart(6,'0') /* .replace(/ /g, '') */
        e.target.value.trim()
      );
    }
  };
  const multiCurrencyAllow = useSelector(
    (state: RootState) => state.blPayment.multiCurrencyAllow
  );
  // const platAmt = (0.0523 * parseFloat(paymentAmount)).toString();
  /*  useEffect(() => {
    if (
      paymentAmount &&
      profileDetails.partnerCountry !== '' &&
      pymtCurr !== '' &&
      pymtMode !== ''
    )
      try {
        axiosInstance
          .get(
            `/api/payment-gateway/v1/platform/charges?pmtAmount=${paymentAmount}&countryCode=US&currency=${selectedCcy}&linerId=1&pmtMode=7`
          )
          .then((response) => {
            setPlatformfee(+response.data.totalPlatformCharge);
            setPlatformChargeConfigId(
              `${response.data?.platformChargeConfigId ?? ''}`
            );
            setTotamt(platformfee ? initAmt + platformfee : initAmt);
          });
      } catch (error) {
        console.error('Error:', error);
      }
  }, [paymentAmount, platformfee]); */
  return (
    <div className=" h-100">
      <div className="border card" style={{ minHeight: "100%" }}>
        <Card.Header
          className="d-flex align-items-center border-bottom sticky-top bg-white"
          style={{ zIndex: 2 }}
        >
          <FontAwesomeIcon
            style={{ position: "relative", left: "0" }}
            icon={faArrowAltCircleLeft}
            className="cursor-pointer fa-lg "
            onClick={_onCloseForm}
          />
          {window.location.pathname === "/payment/new" && (
            <h2 className="fs-0 text-primary ms-2 p-0 m-0">
              {selecteData?.length > 0 || location.pathname == "/payment/new"
                ? t("resource_2:paymentDetails")
                : t("resource_2:addFund")}
            </h2>
          )}
          {window.location.pathname !== "/payment/new" && (
            <h2 className="fs-0 text-primary ms-2">
              {t("resource_2:paymentDetails")}
            </h2>
          )}
          {lastEndpoint == "instructionpayment" && (
            <h2 className="fs-0 text-primary ms-2">
              {t("resource_2:paymentDetails")}
            </h2>
          )}
          {usdPayableAmount && zarPayableAmount ? (
            <h5 className="fs-0">Payable &nbsp;&nbsp;&nbsp;&nbsp;</h5>
          ) : (
            ""
          )}
          {usdPayableAmount ? (
            <h5 className="fs-0">USD {usdPayableAmount}</h5>
          ) : (
            ""
          )}{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {zarPayableAmount ? (
            <h5 className="fs-0">ZAR {zarPayableAmount}</h5>
          ) : (
            ""
          )}
        </Card.Header>
        <div className="card-body" style={{ zIndex: showMainLayout ? 1 : 2 }}>
          {disableFields ? (
            <>
              {
                /*  rbac.rbacDetails.showPlatformFees == 'Y'  */[
                  7, 8, 10,
                ].includes(+paymentMode) && (
                  <>
                    <Form.Label column lg={3} className="fs--1 opacity-75 my-0">
                      Invoice Amount
                    </Form.Label>
                    <Form.Label className={`fs--1 opacity-75 my-0 mx-1`}>
                      <span
                        className="mx-2 my-0"
                        style={{ paddingLeft: "90px" }}
                      >
                        {window.location.pathname.includes("blsliderpayment")
                          ? `${selectedCcy} ${totalInvAmt}`
                          : `${selectedCcy} ${totalInvAmt}`}
                      </span>
                    </Form.Label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "18px",
                      }}
                    >
                      <Form.Label
                        column
                        lg={3}
                        className="fs--1 opacity-75 my-0"
                      >
                        Platform Fees
                      </Form.Label>
                      <Form.Label className="fs--1 opacity-75 my-0 mx-1">
                        <span
                          className="mx-2 my-0"
                          style={{ paddingLeft: "90px" }}
                        >
                          {window.location.pathname.includes("blsliderpayment")
                            ? `${selectedCcy} ${formatNumber(platformfee)}`
                            : `${selectedCcy} ${formatNumber(platformfee)}`}
                          <a
                            href={`${process.env.REACT_APP_CLIENT_APP_HOST}/api/edo-rest-v1/v1/payment/getInvoicePdf/${pymtId}`}
                            target="_blank"
                            style={{ marginLeft: "0.7rem", fontSize: "12px" }}
                          >
                            Invoice
                          </a>
                        </span>
                      </Form.Label>
                    </div>
                  </>
                )
              }
              <Form.Group as={Row} controlId="payableAmount.input">
                <Form.Label column lg={5} className="fs--1">
                  {t("resource_2:payableAmount")}
                </Form.Label>

                {multiCurrencyAllow &&
                  localAmount &&
                  localAmount != "" &&
                  localccy &&
                  localccy != "" ? (
                  <Col lg={6}>
                    <div className="d-flex py-1 ">
                      <div className="pe-5">
                        <p className="text-600 fs--1 fw-medium mb-0">
                          {selectedCcy}
                        </p>{" "}
                        <h4 className="text-800 mb-0">{paymentAmount} </h4>
                      </div>
                      <div className="pe-5">
                        <p className="text-600 fs--1 fw-medium mb-0">
                          {localccy}
                        </p>{" "}
                        <h4 className="text-800 mb-0">{localAmount} </h4>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <Col lg={6}>
                    <InputGroup>
                      <Col lg={5}>
                        <Form.Control
                          value={selectedCcy}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                      <Col lg={7}>
                        <Form.Control
                          aria-label={t("resource_2:payableAmount")}
                          aria-describedby="payable-amount"
                          value={formatNumber(paymentAmount)}
                          disabled={disableFields}
                        />
                      </Col>
                    </InputGroup>
                  </Col>
                )}
              </Form.Group>
            </>
          ) : (
            <>
              <PayableAmount dataCountry={paymentCountry} />
            </>
          )}
          <hr />

          <div>
            {pmtId != 0 &&
              invoiceDetails?.data !== undefined &&
              invoiceDetails?.data.length >= 0 && (
                <h6>
                  Payable Invoice{" "}
                  <span className="badge badge-soft-primary rounded-pill ms-2 mt-2">
                    {pmtNumberdataCount.length}
                  </span>
                </h6>
              )}
            {pmtId != 0 && (
              <PaymentInvoiceList
                selectedCcy={selectedCcy}
                pmtID={pymtId}
                inputValue={inputValue}
              />
            )}
          </div>

          <Form.Group className="pb-1 pe-1 ps-3" controlId="blPayment.pmtId">
            <Form.Control type="hidden" className="fs--1" value={pmtId} />
          </Form.Group>
          <Form.Group className="pb-3 pe-0 ps-0" controlId="blPayment.input1">
            <Form.Label className="fs--1">
              {t("resource_2:paymentMode")}
            </Form.Label>

            <Form.Select
              aria-label="Payment Mode"
              className={`${classNames({ "text-400": !paymentMode })} fs--1`}
              onChange={onChangePaymentMode}
              value={paymentMode}
              disabled={disableFields}
            >
              {/*  {pymtModeOptions.filter((item: any) => item.value != 8 || (cdnCountry == paymentCountry) || pmtStatus) */}
              {pymtModeOptions
                .filter(
                  (item: any) =>
                  (item.value !== 8 ||
                    cdnCountry == paymentCountry ||
                    pmtStatus)
                )

                .map((option: any) =>
                  option.value == 2 &&
                    selecteData?.length == 0 &&
                    window.location.pathname === "/payment/new" ? (
                    <></>
                  ) : option.value == 2 && rbac.rbacDetails.useCredit != "Y" ? (
                    <></>
                  ) : (
                    <option
                      className="text-dark"
                      key={option.value}
                      value={option.value}
                    >
                      {option.text}
                      {option.value == 2 && totalCreditAmount
                        ? " -- USD " +
                        formatNumber(totalCreditAmount) +
                        "(" +
                        creditBalanceList.length +
                        ")"
                        : ""}
                    </option>
                  )
                )}
              {/*  <option className='text-dark' value='1'>
                Bank Transfer
              </option>
              <option className='text-dark' value='2'>
                Credit Balance
              </option>
              <option className='text-dark' value='3'>
                PayCargo
              </option> */}
            </Form.Select>
          </Form.Group>

          {paymentMode == 6 && profileDetails.partnerType === "customer" ? (
            <div
              className="co-lg-12"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#f5822a",
              }}
            >
              {" "}
              There can be additional charges applicable by Paycargo as a part
              of their platform utilization fee{" "}
            </div>
          ) : (
            ""
          )}

          {paymentMode ? (
            <>
              {paymentMode == 1 || paymentMode == 3 || paymentMode == 9 ? (
                <>
                  <Form className="p-0">
                    {paymentMode == 1 ? (
                      <>
                        <Row className="mb-3">
                          <div className="pb-0 pe-3 ps-3 mt-3">
                            {bankData.length == 0 ? (
                              <span>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  To add Bank Accounts please
                                  <Link to={"/user/profile"}>
                                    {" click here"}
                                  </Link>
                                </p>
                              </span>
                            ) : (
                              <></>
                            )}
                            {!disableFields && (
                              <Form.Group controlId="blPayment.input2">
                                <Form.Select
                                  aria-label="Bank Name"
                                  className="fs--1"
                                  disabled={disableFields}
                                  value={selected}
                                  onChange={handleChange}
                                >
                                  {
                                    /*   {disableFields
                                ? optionsDummy.map((option: any) => (
                                    <option
                                      key={option.value}
                                      value={option.value}>
                                      {option.text}
                                    </option>
                                  ))
                                : */ //}

                                    bankData.map((option: any) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.text}
                                      </option>
                                    ))
                                  }
                                </Form.Select>
                              </Form.Group>
                            )}
                          </div>
                          <Col>
                            <Form.Group controlId="blPayment.input2">
                              <Form.Label className="fs--1">
                                {t("resource_2:bankName")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="fs--1"
                                disabled={disableFields}
                                value={bnkName}
                                onChange={(e) => setBnkName(e.target.value)}
                              />
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group controlId="blPayment.input3">
                              <Form.Label
                                className="fs--1"
                                disabled={disableFields}
                              >
                                {t("resource_2:A/CNo")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="fs--1"
                                disabled={disableFields}
                                value={acName}
                                onChange={(e) => setAcName(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        {paymentMode != 9 ? <div style={{ fontWeight: "bold" }}>
                          <p style={{ fontSize: "12px", marginBottom: "6px" }}>
                            Please use the ODeX Ref Number while transacting on
                            PayCargo. You can visit PayCargo here{" "}
                            <a
                              href="https://paycargo.com "
                              target="_blank"
                              style={{ fontWeight: "bold" }}
                            >
                              https://paycargo.com{""}
                            </a>
                          </p>
                          <p style={{ fontSize: "12px", marginBottom: "16px" }}>
                            Alternatively you can also enter the PayCargo
                            Payment Ref ID for the set of invoice/s for which
                            you have initiated a payment on PayCargo.
                          </p>
                        </div> : ""}
                      </>
                    )}
                    <fieldset>
                      {paymentMode == 1 && paymentCountry != "PK" ? (
                        <div className="mb-2" style={{ fontWeight: "bold" }}>
                          <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                            {t(
                              "resource_message:paymentModeMesForBankTransfer"
                            )}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <legend> {t("resource_3:yourReferenceNo")}</legend>
                      <hr />
                      <Form.Group className="mb-2" controlId="blPayment.input4">
                        {paymentCountry === "PK" ? (
                          ""
                        ) : (
                          <>
                            <Form.Label
                              className={paymentMode == 9 ? "d-none" : "fs--1 "}
                              htmlFor="auto"
                            >
                              <input
                                type="radio"
                                id="auto"
                                value="auto"
                                name="refNo"
                                onChange={() => {
                                  setInputValue("");
                                  setRadioValue("auto");
                                  // props.refInput.disabled = 'true';
                                }}
                                checked={radioValue === "auto" ? true : false}
                                disabled={paymentMode == 9 ? true : disableFields}
                              />{" "}
                              {t("resource_2:oDeXGenerate")}
                            </Form.Label>
                          </>
                        )}
                        <Form.Label className="fs--1" htmlFor="generate">
                          <input
                            type="radio"
                            id="generate"
                            value="generate"
                            name="refNo"
                            onChange={(e) => {
                              setInputValueTemp(inputValue);
                              setInputValue("");
                              setRadioValue("generate");
                            }}
                            style={
                              paymentMode == 9 || paymentCountry === "PK"
                                ? { marginLeft: "0px" }
                                : { marginLeft: "20px" }
                            }
                            checked={radioValue === "generate" ? true : false}
                            disabled={!editPayment ? disableFields : false}
                          />{" "}
                          {paymentMode == 9
                            ? "Cheque No."
                            : paymentCountry === ""
                              ? t("resource_2:slipNumber")
                              : t("resource_5:iHaveMyRefNo")}
                        </Form.Label>
                        {paymentCountry === "SG" || paymentCountry === "MY" ? (
                          <span
                            onClick={() => {
                              setPaymentRefInfo(true);
                            }}
                          >
                            {radioValue === "generate" && paymentMode == 1 ? (
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="ps-2 pe-2"
                                style={{ cursor: "pointer" }}
                              ></FontAwesomeIcon>
                            ) : (
                              ""
                            )}
                          </span>
                        ) : (
                          ""
                        )}

                        <Form.Control
                          className="fs--1"
                          disabled={
                            (editPayment === true && radioValue === "auto") ||
                              !editPayment
                              ? disableFields ||
                              (radioValue === "auto" ? true : false)
                              : false
                          }
                          type="text"
                          // name='refInput'
                          ref={modalCloseFocus}
                          value={inputValue}
                          onChange={(e) =>
                            setInputValue(
                              e.target.value /* .replace(/ /g, '') */
                            )
                          }
                          onBlur={handleInputBlur}
                          maxLength={pymtConf?.maxLength}
                          minLength={pymtConf?.minLength}
                          placeholder={
                            pymtConf?.minLength <= 1
                              ? `Up to ${pymtConf?.maxLength} characters`
                              : `Between ${pymtConf?.minLength} to ${pymtConf?.maxLength} characters`
                          }
                        />
                      </Form.Group>
                      {profileDetails.partnerType === "liner" &&
                        pmtStatus.toUpperCase() !== "PAID" &&
                        pmtStatus !== "1" &&
                        pmtStatus?.toUpperCase() != "REJECTED" &&
                        pmtStatus?.toUpperCase() != "PAID_FROM_SAP" &&
                        pmtStatus?.toUpperCase() != "AUTO_APPROVED" &&
                        rbac.rbacDetails.paymentApproveReject === "Y" && (
                          <Form.Group controlId="blPayment.input2">
                            <Form.Label className="fs--1">
                              {t("resource_2:bankRefNo")}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="fs--1"
                              // disabled={disableFields}
                              value={bankRefNoLiner}
                              onChange={(e) =>
                                setBankRefNoLiner(e.target.value)
                              }
                            />
                          </Form.Group>
                        )}
                      {profileDetails.partnerType === "liner" &&
                        pmtStatus.toUpperCase() !== "PAID" &&
                        pmtStatus?.toUpperCase() != "PAID_FROM_SAP" &&
                        pmtStatus?.toUpperCase() != "AUTO_APPROVED" &&
                        pmtStatus !== "1" &&
                        pmtStatus?.toUpperCase() != "REJECTED" &&
                        rbac.rbacDetails.showAdminRefNo === "Y" && (
                          <Form.Group controlId="blPayment.input4">
                            <Form.Label className="fs--1">
                              {t("resource_2:adminRefNo")}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="fs--1"
                              // disabled={disableFields}
                              value={adminRefNumber}
                              onChange={(e) =>
                                setAdminRefNumber(e.target.value)
                              }
                            />
                          </Form.Group>
                        )}
                      {profileDetails.partnerType === "liner" &&
                        pmtStatus.toUpperCase() !== "PAID" &&
                        pmtStatus?.toUpperCase() != "PAID_FROM_SAP" &&
                        pmtStatus?.toUpperCase() != "AUTO_APPROVED" &&
                        pmtStatus !== "1" &&
                        rbac.rbacDetails.paymentApproveReject === "Y" && (
                          <legend>{t("resource_2:user_remarks")}</legend>
                        )}
                      {profileDetails.partnerType === "liner" &&
                        pmtStatus.toUpperCase() !== "PAID" &&
                        pmtStatus?.toUpperCase() != "PAID_FROM_SAP" &&
                        pmtStatus?.toUpperCase() != "AUTO_APPROVED" &&
                        pmtStatus !== "1" &&
                        rbac.rbacDetails.paymentApproveReject === "Y" && (
                          <Form.Group
                            className="mb-2 mt-2"
                            controlId="blPayment.input4"
                          >
                            <Form.Control
                              className="fs--1"
                              type="text"
                              onChange={(e) => {
                                setrejectionMsg(e.target.value);
                              }}
                              disabled={
                                pmtStatus?.toUpperCase() == "REJECTED"
                                  ? true
                                  : false
                              }
                              value={
                                pmtStatus?.toUpperCase() == "REJECTED"
                                  ? linerRemarks
                                  : rejectionMsg
                              }
                              maxLength={150}
                              minLength={10}
                              placeholder="Enter Remark"
                            />
                          </Form.Group>
                        )}
                      {/*   {radioValue === 'generate' && !inputValue && (
                        <h6 className='text-warning m-0 fs--1'>
                          Please enter your Ref No &nbsp;&nbsp;
                        </h6>
                      )} */}
                      {radioValue === "generate" &&
                        !(!editPayment ? disableFields : false) &&
                        inputValue.length < pymtConf?.minLength &&
                        paymentMode == 1 && (
                          <h6 className="text-warning m-0 fs--1">
                            Please enter your Ref No Between {pymtConf?.minLength} to {pymtConf?.maxLength} characters
                            &nbsp;&nbsp;
                          </h6>
                        )}
                      {!(!editPayment ? disableFields : false) &&
                        inputValue.length < pymtConf?.minLength &&
                        paymentMode == 9 && (
                          <h6 className="text-warning m-0 fs--1">
                            Please enter Cheque No Between {pymtConf?.minLength} to {pymtConf?.maxLength} characters
                            &nbsp;&nbsp;
                          </h6>
                        )}
                      {/*     {radioValue === "generate" && inputValue.length < 6 && paymentMode ==9 && (
                        <h6 className="text-warning m-0 fs--1">
                          Please enter your Cheque no should be in 6 characters
                          &nbsp;&nbsp;
                        </h6>
                      )} */}

                      {(profileDetails.partnerType === "liner" &&
                        pmtStatus.toUpperCase() !== "PAID" &&
                        pmtStatus?.toUpperCase() != "PAID_FROM_SAP" &&
                        pmtStatus?.toUpperCase() != "AUTO_APPROVED" &&
                        pmtStatus !== "1" &&
                        pmtStatus?.toUpperCase() != "REJECTED" &&
                        paymentTransactionDetails?.length > 0 &&
                        rbac?.rbacDetails?.showPaymentTransaction == 'Y') &&
                        <ToggleDiv
                          className='col-lg-12 mb-2 demmurageTable'
                          style={{ zIndex: 1 }}
                          showText={'Click here to view transaction details'}
                          hideText={'Click here to hide transaction details'}
                          initial={false}
                        >
                          <DataTable
                            data={paymentTransactionDetails ?? []}
                            gridName='pmt_transaction_details'
                            columns={transactionDetailsCols}
                            sortable={false}
                            searchable={false}
                            showColumnFilter={false}
                            selection={false}
                            perPage={10}
                            pagination={false}
                            showExport={false}
                            uniqueFilterKey='pmt_transaction_details'
                            autoHeight={true}
                            maxHeight="7rem"
                            staticHiddenCols={[]}
                          />
                        </ToggleDiv>
                      }
                    </fieldset>
                    {isAttachmentMandate || (disableFields == false &&
                      (showPaymentProofAttachment || paymentMode == 9)) ? (
                      <Form.Group controlId="blPayment.input5">
                        <Form.Label className="fs--1 d-flex flex-row mt-3">
                          { } {t("resource_2:bankProof")}
                          {!!loadingFileState && (
                            <td>
                              <UploadIndicator
                                style={{ marginLeft: "20px" }}
                                progress={loadingFileState}
                                fail={loadingFileState === -1}
                              />
                            </td>
                          )}
                        </Form.Label>
                        <Form.Control
                          type="file"
                          disabled={disableFields && !isAttachmentMandate}
                          onClick={() => {
                            setPaymentProofFileName("");
                            setSelectedFileServerPath([null, null]);
                          }}
                          onChange={handleFileChange}
                          style={{ marginBottom: "10px" }}
                        // value={loiUpload}
                        />
                        <>
                          {(isAttachmentMandate || (radioValue === "generate" &&
                            inputValue !== "" &&
                            pymtConf?.paymentProofAttachment === "M" &&
                            paymentMode != 9)
                            ||
                            (
                              inputValue !== "" &&
                              pymtConf?.paymentProofAttachment === "M" &&
                              paymentMode == 9
                            )) &&
                            paymentProofFileName === "" ? (
                            <h6 className="text-warning m-0 fs--1">
                              Please attach Payment Proof &nbsp;&nbsp;
                            </h6>
                          ) : (
                            ""
                          )
                          }
                        </>
                      </Form.Group >
                    ) : (
                      <div>
                        <Form.Label className="fs--1">
                          {showPaymentProofAttachment ? (
                            <>
                              {fileName == "" ||
                                fileName == "undefined" ||
                                fileName == null
                                ? ""
                                : t("resource_2:bankProof")}{" "}
                            </>
                          ) : (
                            ""
                          )}
                        </Form.Label>
                        {
                          ["M", "O"].includes(pymtConf?.paymentProofAttachment) &&
                          <span
                            className="text-primary cursor-pointer"
                            onClick={() => {
                              window.open(
                                `${process.env.REACT_APP_CLIENT_APP_HOST
                                }/api/edo-rest-v2/v2/common/openDocument?id=${pymtId}&moduleTp=PAYMENT_PROOF&linerId=${profileDetails.partnerType === "liner"
                                  ? profileDetails?.partnerId
                                  : selectedLinerId
                                }`,
                                "_blank"
                              );
                            }}
                          >
                            {fileName}
                          </span>
                        }
                      </div>
                    )
                    }
                  </Form >
                </>
              ) : paymentMode == 2 ? (
                <div>
                  <Form.Group
                    as={Row}
                    className="ml-3 mb-2"
                    controlId="payableAmount.input3"
                  >
                    <Col lg={4}>
                      <Form.Label className="fs--1">
                        {t("resource_2:selectedAmount")}
                      </Form.Label>
                    </Col>
                    <Col>
                      <Form.Label className="fs--1">
                        {formatNumber(selectedCreditAmount)}
                      </Form.Label>
                    </Col>
                  </Form.Group>
                  <CreditBalance />
                </div>
              ) : (
                ""
              )}


              <div className="d-flex justify-content-center align-items-center">
                {paymentLoaderCheck ? (
                  <Lottie
                    animationData={infiniteLoop}
                    loop={true}
                    style={{ height: "80px", width: "80px" }}
                  />
                ) : paymentMode == 6 &&
                  pmtStatus !== "1" &&
                  pmtStatus !== "Paid" ? (
                  <>
                    <div style={{ zIndex: 500 }}>
                      <p />
                      <PaycargoCheckout
                        ref={paycargoRef}
                        options={options}
                        pcTransactions={selectedTransactions}
                      />
                    </div>
                  </>
                ) : (paymentMode == 7 || paymentMode == 8) &&
                  pmtStatus !== "1" &&
                  pmtStatus !== "Paid" &&
                  pmtStatus !== "Submitted" &&
                  platformChargeConfigId != "" ? (
                  <>
                    {selectedAmount.length <= 0 ? (
                      "No invoices Selected"
                    ) : (
                      <div style={{ zIndex: 500 }}>
                        <p />
                        <App
                          payload={{
                            invoiceIds: selectedAmount.map((i: any) => i.invID),
                            email: profileDetails.email,
                            linerId: selectedLinerId,
                            countryCode: paymentCountry,
                            pymtMode: paymentMode,
                            platformChargeConfigId: platformChargeConfigId,
                          }}
                          quickPay={false}
                        />
                      </div>
                    )}
                  </>
                ) : paymentMode == 10 &&
                  pmtStatus !== "1" &&
                  pmtStatus !== "Paid" &&
                  pmtStatus !== "Submitted" &&
                  platformChargeConfigId != "" ? (
                  <>
                    {selectedAmount.length <= 0 ? (
                      "No invoices Selected"
                    ) : (
                      <div style={{ zIndex: 500 }} className="w-100">
                        <p />
                        <PayPalCard
                          payload={{
                            invoiceIds: selectedBls.map((i) => i.invid),
                            email: profileDetails.email,
                            linerId: selectedLinerId,
                            countryCode: paymentCountry,
                            pymtMode: paymentMode,
                            platformChargeConfigId: platformChargeConfigId,
                          }}
                          quickPay={false}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <Button
                    variant="falcon-primary"
                    size="sm"
                    className="me-2"
                    hidden={disableFields}
                    onClick={handleSubmit}
                    type="submit"
                    disabled={
                      (loadingFileState !== 0 &&
                        loadingFileState !== 100 &&
                        loadingFileState !== -1) ||
                      (paymentMode == 1 &&
                        radioValue == "generate" &&
                        (inputValue.length < pymtConf?.minLength || inputValue.length > pymtConf?.maxLength)) ||
                      (paymentMode == 1 &&
                        radioValue == "generate" && pymtConf?.paymentProofAttachment == "M" &&
                        (paymentProofFileName == "" ||
                          !selectedFileServerPath[0])) ||
                      (paymentMode == 9 && ((inputValue.length < pymtConf?.minLength || inputValue.length > pymtConf?.maxLength) ||
                        (pymtConf?.paymentProofAttachment == "M" && (paymentProofFileName == "" || !selectedFileServerPath[0]))))
                    }
                  >
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    {t("resource_1:submit")}
                  </Button>
                )}
                <Button
                  className="me-2 d-none"
                  variant="falcon-danger"
                  size="sm"
                  hidden={disableFields}
                >
                  <FontAwesomeIcon icon={faRedo} className="me-2" />
                  {t("resource_1:reset")}
                </Button>
              </div>
              {pmtStatus !== "1" &&
                pmtStatus !== "Paid" &&
                profileDetails.partnerType != "liner" &&
                !isAttachmentMandate &&
                pmtId &&
                pmtStatus !== "Cancelled" ? (
                <div className="d-flex justify-content-center align-items-center">
                  {cancelPaymentLoader ? (
                    <Lottie
                      animationData={infiniteLoop}
                      loop={true}
                      style={{ height: "80px", width: "80px" }}
                    />
                  ) : (
                    <>
                      <Button
                        variant="falcon-primary"
                        size="sm"
                        className="me-2"
                        hidden={editPayment}
                        disabled={
                          pmtStatus == "1" || pmtStatus == "Paid" ? true : false
                        }
                        onClick={() => {
                          dispatch(setEditPayment(true));
                          setLoading(false);
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                        {t("resource_1:edit")}
                      </Button>
                      <Button
                        variant="falcon-danger"
                        size="sm"
                        className="me-2"
                        hidden={editPayment}
                        onClick={() => setPaymentCancel(true)}
                      >
                        <FontAwesomeIcon icon={faCancel} className="me-2" />
                        {t("resource_2:cancelpayment")}
                      </Button>
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}

              {
                profileDetails.partnerType === "liner" &&
                pmtStatus.toUpperCase() !== "PAID" &&
                pmtStatus?.toUpperCase() != "PAID_FROM_SAP" &&
                pmtStatus?.toUpperCase() != "AUTO_APPROVED" &&
                pmtStatus.toUpperCase() !== "CANCELLED" &&
                pmtStatus.toUpperCase() != "REJECTED" &&
                pmtStatus !== "1" &&
                rbac.rbacDetails.paymentApproveReject === "Y" && (
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    {acceptPaymentLoader || rejectPaymentLoader ? (
                      <Lottie
                        animationData={infiniteLoop}
                        loop={true}
                        style={{ height: "80px", width: "80px" }}
                      />
                    ) : (
                      <>
                        <Button
                          variant="success"
                          className="me-4 approve"
                          size="sm"
                          hidden={editPayment}
                          onClick={() => {
                            const payload = {
                              pmtId: pymtId,
                              bnkRefNo: bankRefNoLiner,
                              currency:
                                filteredselectedAmount?.[0]?.invcurr ||
                                pymtCurr ||
                                "USD",
                              adminRefNumber: adminRefNumber,
                              amount: paymentAmount,
                              linerId: linerId,
                              country: paymentCountry,
                              remarks: linerRemarks
                            };
                            if (bankRefNoLiner.length == 0) {
                              toast.dark(
                                t("resource_message:bankRefMandatory")
                              );
                            } else {
                              dispatch(acceptPayment(payload))
                                .unwrap()
                                .then(() => {
                                  setTimeout(() => {
                                    dispatch(updateDashbaordRefresh(true));
                                  }, 3000);
                                  onBack();
                                });
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="me-2 approve"
                          />
                          {t("resource_1:approve")}
                        </Button>
                        <Button
                          variant="danger reject"
                          size="sm"
                          hidden={editPayment}
                          onClick={() => {
                            if (rejectionMsg.length == 0) {
                              toast.dark(
                                t("resource_message:remarksMandatory")
                              );
                            } else {
                              dispatch(
                                rejectPayment({
                                  pmtId: pymtId,
                                  // blId: selectedBlId,
                                  currency: pymtCurr,
                                  rejectionMsg: rejectionMsg,
                                })
                              )
                                .unwrap()
                                .then(() => {
                                  setTimeout(() => {
                                    if (
                                      window.location.pathname.includes(
                                        "blsliderpayment"
                                      )
                                    ) {
                                      dispatch(updateDashbaordRefresh(true));
                                      // navigate("/payment");
                                      onBack();
                                    } else {
                                      navigate("/home");
                                    }
                                  }, 3000);
                                });
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="me-2 reject"
                          />
                          {t("resource_1:reject")}
                        </Button>
                      </>
                    )}
                  </div>
                )
              }
              {
                editPayment && pmtId ? (
                  loading ? (
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Lottie
                        animationData={infiniteLoop}
                        loop={true}
                        style={{
                          height: "40px",
                          width: "40px",
                          alignSelf: "center",
                        }}
                      />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        variant="falcon-primary"
                        size="sm"
                        className="me-2"
                        hidden={!editPayment}
                        type="submit"
                        onClick={() => {
                          setLoading(true);
                          if (multiplePmtRef) {
                            setShowPaymentConfirm(true);
                            setNewPaymentRef(false);
                          } else {
                            checkValidationAndUpdate();
                            // setShowPaymentConfirm(true);
                            // setNewPaymentRef(false);
                          }
                        }}
                        disabled={
                          loadingFileState !== 0 &&
                            loadingFileState !== 100 &&
                            loadingFileState !== -1
                            ? true
                            : loading
                              ? true
                              : inputValue.length >= pymtConf?.minLength
                                ? validationFlag
                                  ? true
                                  : +selectedTotal < 0
                                    ? true
                                    : false
                                : paymentMode != 9 || inputValue.length == 0
                        }
                      >
                        <FontAwesomeIcon icon={faSave} className="me-2" />
                        {t("resource_1:submit")}
                      </Button>
                      <Button
                        className="me-2"
                        variant="falcon-danger"
                        size="sm"
                        hidden={!editPayment}
                        onClick={() =>
                        // _onCloseForm
                        {
                          setRadioValue(radioVal);
                          dispatch(setEditPayment(false));
                          dispatch(updatePymtData(paymentDetails));
                          setInputValue(paymentDetails.odexRefNo != ''
                            ? paymentDetails.odexRefNo
                            : paymentDetails.bankRefNo);
                          if (initialInvoiceDetailsPmtId) {
                            if (initialInvoiceDetailsPmtId?.data.length > 0)
                              dispatch(
                                selectedPmtNumber(
                                  initialInvoiceDetailsPmtId?.data
                                )
                              );
                          }
                        }
                        }
                      >
                        {/*  <FontAwesomeIcon icon={faCancel} className='me-2' /> */}
                        <FontAwesomeIcon icon={faEraser} className="me-2" />
                        {t("resource_1:clear")}
                      </Button>
                    </div>
                  )
                ) : (
                  <></>
                )
              }
            </>
          ) : (
            ""
          )}

          {
            // Submit button for attachment mandate
            isAttachmentMandate && <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            ><Button
              variant="falcon-primary"
              size="sm"
              className="me-2"
              disabled={!selectedFileServerPath[0]}
              onClick={() => {
                dispatch(updatePaymentAttachment({
                  pmtId: mandatePmtId,
                  tempFilePath: selectedFileServerPath[0],
                  tempFileName: selectedFileServerPath[1],
                  linerId
                })).unwrap().then(success => {
                  if (success) {
                    setTimeout(() => {
                      navigate(location.pathname.replace(/\/unpaid.*/, "/unpaid"));
                    }, 2000)
                  }
                })
              }}
            >
                <FontAwesomeIcon icon={faSave} className="me-2" />
                {t("resource_1:submit")}
              </Button></div>
          }
        </div >
      </div >
      <Modal
        show={showPaymentConfirm}
        className="modal-dialog-centered"
        onHide={() => {
          setShowPaymentConfirm(false);
          setLoading(false);
          dispatch(resetPaymentLoaders());
        }}
      >
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6">
            Confirm payment Reference Number
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            overflow: "auto",
          }}
        >
          {newPaymentRef ? (
            <>
              <span className="h6 me-2 mb-2">Payment Reference Number</span>
              <input
                type="text"
                className="fs--2 form-control"
                title="payment Ref"
                placeholder="Enter Payment Reference Number"
              />
            </>
          ) : (
            <>
              <div className="mb-2">
                <span className="h6 me-2">
                  Invoice number has different payment Ref No. Do you want to
                  proceed payment with Ref No:
                </span>
                <span className="fw-bold h6">{inputValue} ?</span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <Button
                  variant="falcon-primary"
                  size="sm"
                  className="me-2"
                  onClick={() => {
                    setShowPaymentConfirm(false);
                    handleUpdate();
                    setTimeout(() => {
                      dispatch(updateDashbaordRefresh(true));
                    }, 3000);
                  }}
                  disabled={inputValue.length > 0 ? false : true}
                >
                  Yes
                </Button>
                <Button
                  className="me-2"
                  variant="falcon-danger"
                  size="sm"
                  onClick={() => {
                    setShowPaymentConfirm(false);
                    setLoading(false);
                    dispatch(resetPaymentLoaders());
                  }}
                >
                  No
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
        {newPaymentRef && (
          <Modal.Footer
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="falcon-primary"
                size="sm"
                className="me-2"
                onClick={() => {
                  modalCloseFocus.current.focus();
                  setShowPaymentConfirm(false);
                }}
              >
                ok
              </Button>
            </div>
          </Modal.Footer>
        )}
      </Modal>

      <Modal
        show={showDuplicacy}
        onHide={() => {
          setshowDuplicacy(false);
          setLoading(false);
          dispatch(resetPaymentLoaders());
        }}
      >
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6">
            {duplicayMsg}.Do you want to proceed with this Reference No?
          </Modal.Title>
        </Modal.Header>
        {/*       <Modal.Body
          style={{
            overflow: 'auto',
          }}
        >
         </Modal.Body> */}
        <div className="d-flex justify-content-center align-items-center p-2">
          <Button
            variant="falcon-primary"
            size="sm"
            className="me-2"
            onClick={() => {
              setshowDuplicacy(false);
              handleDuplicacy();
            }}
          // disabled={inputValue.length > 0 ? false : true}
          >
            Yes
          </Button>
          <Button
            className="me-2"
            variant="falcon-danger"
            size="sm"
            onClick={() => {
              setpaymentRefFocus(true);
              setshowDuplicacy(false);
              setLoading(false);
              dispatch(resetPaymentLoaders());
            }}
          >
            No
          </Button>
        </div>
      </Modal>

      <Modal
        show={showEditDuplicacy}
        onHide={() => {
          setShowEditDuplicacy(false);
          setLoading(false);
          dispatch(resetPaymentLoaders());
        }}
      >
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6">
            {editDuplicayMsg}.
            {methodName &&
              methodName.toString().trim() !== "" &&
              " Do you want to proceed with this Reference No?"}
          </Modal.Title>
        </Modal.Header>
        {/*       <Modal.Body
          style={{
            overflow: 'auto',
          }}
        >
         </Modal.Body> */}
        {methodName && methodName.toString().trim() !== "" ? (
          <div
            style={{ marginBottom: "1rem" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              variant="falcon-primary"
              size="sm"
              className="me-2"
              onClick={() => {
                setShowEditDuplicacy(false);
                if (methodName && methodName.toString().trim() !== "") {
                  if (methodName === "handleUpdate") {
                    handleUpdate();
                  }
                }
              }}
            // disabled={inputValue.length > 0 ? false : true}
            >
              Yes
            </Button>
            <Button
              className="me-2"
              variant="falcon-danger"
              size="sm"
              onClick={() => {
                setpaymentRefFocus(true);
                setShowEditDuplicacy(false);
                setLoading(false);
                dispatch(resetPaymentLoaders());
              }}
            >
              No
            </Button>
          </div>
        ) : (
          <div
            style={{ marginBottom: "1rem" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              variant="falcon-primary"
              size="sm"
              className="me-2"
              onClick={() => {
                setShowEditDuplicacy(false);
                setpaymentRefFocus(true);
              }}
            // disabled={inputValue.length > 0 ? false : true}
            >
              Okay
            </Button>
          </div>
        )}
      </Modal>

      <Modal
        show={showConfirmMsg}
        onHide={() => {
          setShowConfirmMsg(false);
          dispatch(resetPaymentLoaders());
          setLoading(false);
        }}
      >
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6">
            {/*  {pmtReftabledata.length == 0
              ? 'Do you want to cancel Payment'
              : pmtNumberdata.length > pmtReftabledata.length
              ? 'Do you want to remove Invoice from the Payment '
              : ''} */}
            Do you want to proceed with the payment
          </Modal.Title>
        </Modal.Header>

        <div
          style={{ marginBottom: "1rem" }}
          className="d-flex justify-content-center align-items-center"
        >
          <Button
            variant="falcon-primary"
            size="sm"
            className="me-2"
            onClick={() => {
              setShowConfirmMsg(false);
              dispatch(
                updatePaymentReferenceNo({
                  inputValue: inputValue,
                  selectedCcy: pymtCurr,
                  selectedPymtId: pymtId,
                  pymtAmt: paymentAmount,
                  country: paymentCountry,
                  refType: paymentMode == 9 ? 3 : radioValue === "auto" ? 1 : 2,
                })
              )
                .unwrap()
                .then(() => {
                  setTimeout(() => {
                    navigate("/payment");
                    dispatch(updateDashbaordRefresh(true));
                    dispatch(updateSelectedRowsInvoice([]));
                    dispatch(updateSelectedAmount([]));
                  }, 2000);
                });
              // handleUpdate();
            }}
          // disabled={inputValue.length > 0 ? false : true}
          >
            Yes
          </Button>
          <Button
            className="me-2"
            variant="falcon-danger"
            size="sm"
            onClick={() => {
              setpaymentRefFocus(true);
              setShowConfirmMsg(false);
              setLoading(false);
              dispatch(resetPaymentLoaders());
            }}
          >
            No
          </Button>
        </div>
      </Modal>

      <Modal show={showPaymentCancel} onHide={() => setPaymentCancel(false)}>
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6">
            Do you want to Cancel the Payment?
          </Modal.Title>
        </Modal.Header>

        <div className="d-flex justify-content-center align-items-center p-2">
          <Button
            variant="falcon-primary"
            size="sm"
            className="me-2"
            onClick={() => {
              setPaymentCancel(false);
              dispatch(
                cancelPayment({
                  pmtId: pymtId,
                  // blId: selectedBlId,
                  currency: pymtCurr,
                })
              )
                .unwrap()
                .then(() => {
                  if (window.location.pathname.includes("blsliderpayment")) {
                    onBack();
                    dispatch(updateSelectedRowsInvoice([]));
                    dispatch(updateSelectedAmount([]));
                    setTimeout(() => {
                      dispatch(updateDashbaordRefresh(true));
                    }, 2000);
                  } else {
                    setTimeout(() => {
                      navigate("/home");
                    }, 3000);
                  }
                });
            }}
          // disabled={inputValue.length > 0 ? false : true}
          >
            Yes
          </Button>
          <Button
            className="me-2"
            variant="falcon-danger"
            size="sm"
            onClick={() => {
              setPaymentCancel(false);
              setLoading(false);
              dispatch(resetPaymentLoaders());
            }}
          >
            No
          </Button>
        </div>
      </Modal>
      {
        rbac.rbacDetails.videoPaypalPopup == "Y" && location.pathname.includes("new") &&
        <VideoPopup show={showVideoPopup} setShow={setShowVideoPopup} />
      }
      <PaymentRefUserGuid
        showPaymentRefInfo={showPaymentRefInfo}
        setPaymentRefInfo={setPaymentRefInfo}
        selectedCurrency={filteredselectedAmount?.[0]?.invcurr}
      />
    </div >
  );
};

export default BlPayment;
